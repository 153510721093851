body[data-draw="started"] div#map *,
body[data-draw="started"] div#map{
    cursor: crosshair !important;
}

a,
a *,
input[type="radio"]+label,
input[type="checkbox"]+label,
input[type="radio"]+label *,
input[type="checkbox"]+label *,
input[type="checkbox"] *,
ul.selector *,
ul.actions *,
ul.sources *,
p.links *,
button,
button *,
div.searchBar > img,
div.searchBar > button.back,
div.bookmarks article.regions section,
div.bookmarks article.regions section *,
div.reportsList section[data-region],
div.reportsList section[data-region] *,
div.reportsList ul.countries_list li,
div.reportsList ul.countries_list li *,
div.carousel img.fullscreen,
article section input[type="file"]+label,
div.map div.map-attributions,
div.map div.map-attributions *,
div.carousel ul.controls li,
div.tabs.open > nav *{
    cursor: pointer;
}