div.tooltip{
    --tooltip-text-height: 40px;
    position: absolute;
    bottom: 75px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    font-size: 1rem;
    font-weight: 500;
    opacity: 0;
    cursor: pointer;
}

div.tooltip *{
    cursor: pointer;
}

div.tooltip > p > span.name > a{
    pointer-events: auto;
    color: white;
    text-decoration: none;
}

div.tooltip > p{
    background: var(--black1);
    border-radius: 100px;
    height: var(--tooltip-text-height);
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0 0 0 1px var(--black1);;
    gap: 10px;
    max-width: calc(100vw - 150px);
}

div.tooltip > p > span.icon{
    background: white;
    border-radius: 100%;
    width: var(--tooltip-text-height);
    aspect-ratio: 1;
    height: var(--tooltip-text-height);
    background-repeat: no-repeat;
}

div.tooltip > p > button.close{
    background-image: url('../images/close.svg');
    background-size: 75%;
    background-position: center;
    background-repeat: no-repeat;
    width: var(--tooltip-text-height);
    height: var(--tooltip-text-height);
    aspect-ratio: 1;
}

div.tooltip > p > span.name{
    display: -webkit-box;
    overflow: hidden;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}


/*-----------------------------------------------------------------------------------------------*/
/* STATES                                                                                        */
/*-----------------------------------------------------------------------------------------------*/

div.tooltip[data-loading]{
    opacity: 1;
}

div.tooltip[data-loading] > p > span.icon{
    opacity: 1;
    background-image: 
        url('../images/loading-small-black.svg'),
        var(--poi-icon-gradient);
}

div.tooltip[data-loading] > p > span.name{
    opacity: 0;
}

div.tooltip[data-family]{
    opacity: 1;
}

div.tooltip[data-family] > *{
    pointer-events: auto;
}

div.tooltip[data-hidden]{
    opacity: 0;
}
div.tooltip[data-hidden] > *{
    pointer-events: none;
}

div.tooltip[data-family="topos"] > p > span.icon{
    background-image: 
        url('../images/topo-black.svg'),
        var(--poi-icon-gradient);
}
div.tooltip[data-family="refuges"] > p > span.icon{
    background-image: 
        url('../images/refuge-black.svg'),
        var(--poi-icon-gradient);
}
div.tooltip[data-family="sorties"] > p > span.icon{
    background-image:
        url('../images/sortie-black.svg'),
        var(--poi-icon-gradient);
}
div.tooltip[data-family="regions"] > p > span.icon{
    background-image: 
        url('../images/mountain-black.svg'),
        var(--poi-icon-gradient);
}
div.tooltip[data-family="stations"] > p > span.icon{
    background-image: 
        url('../images/station-black.svg'),
        var(--poi-icon-gradient);
}
div.tooltip[data-family="webcams"] > p > span.icon{
    background-image: 
        url('../images/webcam-black.svg'),
        var(--poi-icon-gradient);
}
div.tooltip[data-family="tracks"] > p{
    display: none;
}
div.tooltip[data-family="tracks"] > ul.tracks{
    display: block;
}


/*-----------------------------------------------------------------------------------------------*/
/* DESKTOP                                                                                       */
/*-----------------------------------------------------------------------------------------------*/

html.desktop div.tooltip{
    bottom: 120px;
}


/*-----------------------------------------------------------------------------------------------*/
/* ANIMATIONS                                                                                    */
/*-----------------------------------------------------------------------------------------------*/

div.tooltip{
    transition-property: opacity;
    transition-duration: 0.25s;
}