/*-----------------------------------------------------------------------------------------------*/
/* STYLING                                                                                       */
/*-----------------------------------------------------------------------------------------------*/

div.articles{
    height: 100%;
    overflow-y: scroll;
    position: absolute;
    top:  0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    background: black;
    opacity: 0;
    pointer-events: none;
    padding: 50px 0 50px 0;
}


div.articles:empty{
	background-image: url('../images/loading.svg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 30px;
	display: none;
}

div.articles > *{
	opacity: 0;
}

article p{
	padding-bottom: 5px;
	line-height: 1.6em;
}

article p.warn{
	display: flex;
	gap: 5px;
	align-items: center;
	line-height: 1rem;
	font-size: 0.85rem;
}

article p.warn img{
	height: 25px;
}

article p.graph{
	overflow-x: scroll;
	overflow-y: hidden;
	background-image: url('../images/graph.png');
	background-attachment: local;
	background-position: top left 5px;
	position: relative;
	padding: 0;
	margin: 0;
}

article p.graph  span.graph-background{
	background-image: url('../images/graph-background.png');
	position: fixed;
	display: block;
	width: 871px;
	height: 675px;
	padding: 0;
	margin: 0;
	margin-left: -1px;
	/* padding-left: 10px; */
}

article p.graph::after{
	position: relative;
	content: '';
	display: block;
	width: 871px;
	height: 675px;
}

article p.legend{
	color: #919191;
    font-size: 0.8rem;
    line-height: 1rem;
    display: block;
    text-align: center;
}

article ul{
	list-style: disc;
	padding-left: 15px;
	line-height: 1.6em;
}

article ol{
	padding-left: 15px;
	line-height: 1.6em;
}

article h1{
	position: relative;
	font-size: 4.25rem;
	line-height: 4.25rem;
	font-weight: 100;
	overflow: hidden;
	padding: 10px 35px 10px 0;
	display: flex;
	gap: 1rem;
}

article h1 > img{
	height: 4.25rem;
}

article input.title,
article h2{
	position: relative;
	font-size: 2.25rem;
	line-height: 2.5rem;
	font-weight: 700;
	display: flex;
	gap:  5px;
	align-items: center;
	padding-bottom: 20px;
}

div.articles > article > section > input[type="text"].title{
	background: transparent;
	color: white;
	width: 100%;
	height: 45px;
	padding: 5px 30px 5px 5px;
	cursor: text;
	box-shadow: 0 0  1px #3a3a3a;
	border-radius: 0;
	background-image: url('../images/draw.svg');
	background-repeat: no-repeat;
	background-position: center right 5px;
	background-size: 35px;
    text-overflow: ellipsis;
}

div.articles > article input.bookmark{
	display: none;
}

div.articles > article input.bookmark+label{
	display: block;
	width: 40px;
	height: 40px;
	position: absolute;
	top: 10px;
	right: 10px;
	background-image: url('../images/rating.svg');
}


html.mobile div.articles > article input.bookmark+label{
	top: 70px;
}

div.articles > article input.bookmark:checked+label{
	background-image: url('../images/rating-full.svg');
}

div.articles > article > section > input[type="text"].title:focus,
div.articles > article > section > input[type="text"].title:active,
div.articles > article > section > input[type="text"].title:hover{
	box-shadow: 0 0  1px rgb(var(--snowmap-blue));
}

div.articles > article > section > input[type="text"].title:focus,
div.articles > article > section > input[type="text"].title:active{
	box-shadow: 0 0  1px rgb(var(--snowmap-blue));
	background-image: none;
	padding: 5px;
}

article h2 > img{
	height: 3.25rem;
}

article h3{
	position: relative;
	font-size: 2.4rem;
	line-height: 2.4rem;
	font-weight: 200;
	display: flex;
	align-items: center;
	padding-bottom: 10px;
}

article h3:empty{
	display: none;
}

article p:empty{
	display: none;
}

article h3 > img{
	height: 3.25rem;
}

article h4{
	position: relative;
	font-size: 1.5rem;
	line-height: 1.5rem;
	font-weight: 300;
	display: flex;
	align-items: center;
}

article h4.riskSummary{
	padding: 10px 0 10px 0;
}

article h5{
	font-size: 1em;
	line-height: 1rem;
	font-weight: 300;
	/* text-align: right; */
	padding-bottom: 10px;
	position: relative;
	height: 30px;
	display: flex;
	gap: 10px;
	justify-content: right;
	align-items: center;
}

article h5.warning{
    font-weight: 600;
    text-decoration: underline;
	color: red;
}

article h5.warning::before{
	content: '';
    width: 3rem;
    height: 3rem;
    background-image: url('../images/exposure.svg');
}

article h5.type{
	margin-top: -20px;
}

article h1::first-letter,
article h2::first-letter,
article h3::first-letter,
article h4::first-letter,
article h5::first-letter{
    text-transform: uppercase;
}

article span.date{
	font-size: 0.8rem;
	margin-top: -20px;
	display: block;
	text-align: right;
}


/*----------------------------- Descriptions ------------------------------*/

article section > div p,
article section > div ul,
article section > div ol{
	padding-bottom: 15px;
	line-height: 1.65rem;
}

article section .paragraph{
	position: relative;
}

article section .paragraph.reduced{
	max-height: 150px;
	overflow: hidden;
	padding-bottom: 50px;
	cursor: pointer;
}

article section .paragraph.reduced *{
	cursor: pointer;
}

article section .paragraph.expanded::after,
article section .paragraph.reduced::after{
	content: '';
	height: 40px;
	bottom: 0;
	left: 0;
	right: 0;
	position: absolute;
	background: 
		url('../images/arrow-down.svg')
		bottom center/20px
		no-repeat,
		linear-gradient(0deg, var(--section-gray) 50%, rgba(219,0,0,0) 100%)
		center center
		no-repeat
	;
	cursor: pointer;
}

article section .paragraph.expanded{
	padding-bottom: 20px;
}

article section .paragraph.reduced:hover,
article section .paragraph.expanded:hover{
	box-shadow: 0 1px 0px 0 grey;
}

article section .paragraph.expanded::after{
	background: 
		url('../images/arrow-up.svg')
		bottom center/20px
		no-repeat
	;
}

article section .paragraph.reduced p::after{
	position: relative;
}

article section .paragraph h3{
	font-weight: bold;
	font-size: 1rem;
	padding-bottom: 0.3rem;
}

article section > div a{
	color: rgb(200,200,200);
}

article section > div h2{
	font-size: 1.25rem;
	line-height: 1.5rem;
	font-weight: 700;
	padding: 10px 0 10px 0;
}

article section > div h2::before {
    content: '\25B6';
    font-size: 0.6rem;
	font-weight: 300;
    vertical-align: bottom;
    padding-right: 0.75rem;
}

article section > div h3{
	font-size: 1.3rem;
	line-height: 1.3rem;
	
}


/*------------------------------- Photos -------------------------------*/

article section > ul.photos{
	display: grid;
	grid-gap: 2px;
	grid-template-columns: repeat(3, 1fr);
	list-style: none;
	padding: 10px 0 10px 0;
}

article section > ul.photos > li:nth-child(n+10){
	display: none;
}

article section > ul.photos > li.moreImages{
	display: list-item;
	background-image: url('../images/more-images.svg');
	background-size: contain;
}

article section > ul.photos > li.blank{
	display: none;
}

article section > ul.photos > li{
	aspect-ratio: 1 / 1;
	background-size: cover;
	position: relative;
	cursor: pointer;
}

html.desktop article section > ul.photos > li:hover{
	margin: 5px;
}

article section > ul.photos > li > span{
	position: absolute;
	top: 20px;
	right: 20px;
	font-weight: 600;
	font-size: 0.75rem;
	line-height: 0.3rem;
	border: 2px solid white;
	border-radius: 100%;
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgb(30,30,30);
	overflow: hidden;
}


/*------------------------------- Data list -------------------------------*/

article section > ul.data,
article section > p.links,
article section > ul.actions{
	display: flex;
	flex-direction: column;
	gap: 5px;
	padding: 10px 0 10px 0;
}

article section > p.links{
	padding-top: 50px;
}

article section > ul.data > li.data-title{
	background: none;
	color: rgb(200,200,200);
}

article section > ul.data > li.striken *{
	color: rgb(120,120,120) !important;
}

article section > ul.data > li.striken > img{
	opacity: 0.25;
}

article section > ul.data > li.striken > span.title{
	text-decoration: line-through;
}

article section > ul.data > li > span.title{
	color: rgb(200,200,200);
	padding-left: 5px;
	white-space: nowrap;
	overflow: hidden;
}

article section > ul.data > li > span.value{
	width: 85px;
	padding-right: 10px;
	text-align: center;
	position: relative;
	overflow: hidden;
	white-space: nowrap;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	height: var(--tab-button-height);
}

article section > ul.data > li > span.value > img{
	height: var(--tab-button-height);
	display: block;
}

article section > ul.data > li > span.value::after{
	content: '\02022';
	color: rgb(200,200,200);
	position: absolute;
	right: 0;
	top: 0;
	height: var(--tab-button-height);
	display: flex;
	align-items: center;
}

div.startup-messages div.actions button,
div.update-window div.actions button,
div.donate-window div.actions button,
article section input[type="file"]+label,
article section > ul.actions > li,
article section > p.links > a.external,
article section > ul.data > li{
	background-color: var(--tab-button-color);
	height: var(--tab-button-height);
	display: flex;
	align-items: center;
	gap: 10px;
	font-size: 1rem;
	flex-direction: row;
	color: white;
}

article section input[type="file"]{
	display: none;
}

div.startup-messages div.actions button,
div.update-window div.actions button,
div.donate-window div.actions button,
article section input[type="file"]+label,
article section > ul.actions > li,
article section > p.links > a.external{
	border: 1px solid rgb(80, 80, 80);
}

article section > p.links > a.external{
	background-image: url('../images/link.svg');
	background-repeat: no-repeat;
	background-position: center right;
	font-weight: normal;
}

article section > p.links > a.external:hover{
	text-decoration: none;
}

article section input[type="file"]+label > img,
article section > ul.actions > li > img,
article section > p.links > a.external > img,
article section > ul.data > li > img{
	height: var(--tab-button-height);
}

article section > ul.actions > li > input[type=radio]::after{
	width: 6px;
    height: 6px;
    border-radius: 15px;
    background-color: none;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid rgb(50,50,50);
    box-shadow: white 0 0 0 1px;
    background: none;
    opacity: 0.5;
}

article section > ul.actions > li > span{
	flex-grow: 1;
}

article section > ul.actions > li > input[type=radio]+label > img{
	height: calc(var(--tab-button-height) * 0.85);
}

article section > ul.actions > li > input[type=radio]:checked+label{
    opacity: 1;
}

article section > ul.actions > li > input[type=radio]{
	flex-basis: 10px;
	display: block;
	width: 0;
	overflow: visible;
	-webkit-appearance: none;
	appearance: none;
}

article section > ul.actions > li > input[type=radio]:checked::after{
	background: white;
    opacity: 1;
}

article section > ul.actions > li > input[type=radio]+label{
    opacity: 0.5;
	margin: 0 10px 0 -10px;
	display: flex;
	align-items: center;
}

article section > ul+*:not(ul){
	padding-top: 20px;
}

article section > div+h3{
	padding-top: 20px;
}

article section > p+h3{
	padding-top: 30px;
}


/*------------------------------- Diagrams -------------------------------*/

article section > .diagrammeEnneigement{
	padding-top: 40px;
}

article section > .diagrammeEnneigement > h2{
    display: block;
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 800;
    margin: -5px 0 -5px 0;
    padding: 0;
}

article section > .diagrammeEnneigement > img{
	width: 100%;
}

article section > .diagrammeEnneigement > h3{
    display: block;
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 200;
    padding: 0;
}

article section > .diagrammeEnneigement > h2::before{
    display: none;
}

article section > div.icons {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

article section > div.icons img {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    max-height: 80px;
    min-width: 0;
}


/*------------------------------- Selectors -------------------------------*/

ul.filter,
ul.selector{
	flex-direction: row;
	padding: 0 10px 10px 10px;
	margin: 0 -10px 10px -10px;
	gap: 15px;
	touch-action: pan-x;
	overflow-x: scroll;
	list-style: none;
}

ul.selector li{
	list-style: none;
}

ul.selector li.blank{
	display: none;
}

ul.filter{
	display: none;
	padding: 10px;
}

ul.filter,
ul.selector.visible{
	display: flex;
}

ul.filter::-webkit-scrollbar,
ul.selector::-webkit-scrollbar{
	display: none;
}

ul.filter > li > input,
ul.selector > li > input{
	display: none;
}
	
ul.filter > li > input+label,
ul.selector > li > input+label{
    border: 2px solid rgb(50,50,50);
    border-radius: 30px;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0 30px 0 20px;
    color: rgb(150,150,150);
    white-space: nowrap;
    font-size: 1rem;
    user-select: none;
}

ul.filter > li > input+label > img,
ul.selector > li > input+label > img{
	height: 100%;
	margin-left: -10px;
	padding-right: 5px;
	opacity: 0.5;
}

ul.filter > li > input:checked+label ,
ul.selector > li > input:checked+label {
    background-repeat: no-repeat;
    background-position: right;
    color: white;
    border: 2px solid rgb(200,200,200);
    line-height: 40px;
}

ul.filter > li > input[type="checkbox"]:checked+label,
ul.selector > li > input[type="checkbox"]:checked+label {
    background-image: url("../images/validate.svg");
}

ul.filter > li > input:checked+label > img,
ul.selector > li > input:checked+label > img{
	opacity: 1;
}


/*------------------------------- Report -------------------------------*/

div.articles article section.report p.icons{
	display: flex;
}

div.articles article section.report p.icons img{
	min-width: 0;
}

div.articles article section.report p.fresh_snow > span,
div.articles article section.report h4.date{
	display: flex;
	align-items: center;
	height: 4rem;
}

div.articles article section.report p.fresh_snow > span > img,
div.articles article section.report h4.date > img{
	height: 4rem;
}

div.articles article section.report h4::after{
    content: '';
    width: 2rem;
    aspect-ratio: 1;
    background-image: url('../images/clock.svg');
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 10px;
    flex-shrink: 0;
}

div.articles article section.report h4{
    font-size: 1rem;
    justify-content: end;
    display: block;
    opacity: 0.5;
    text-align: right;
}

div.articles article section.report h4.warning{
    opacity: 1;
    font-weight: 600;
    text-decoration: underline;
}

div.articles article section.report h4.warning::after{
    width: 3rem;
    background-image: url('../images/exposure.svg');
}


/*------------------------------- Webcams -------------------------------*/


div.articles article.webcam p.view{
	position: relative;
	width: 100%;
	height: 200px;
	background-color: #2f2f2f;
	background-size: cover;
	cursor: pointer;
}

div.articles article.webcam p.view.no-image{
	background-image: url(../images/no-webcam-image.svg);
	background-position: center center;
	background-size: 10%;
	cursor: auto;
}

div.articles article.webcam p.view.no-image .fullscreen{
	display: none;
}

div.articles article.webcam p.image-date{
	text-align: center;
	font-size: 0.8rem;
	color: rgb(200,200,200);
	font-weight: bold;
}

div.articles article.webcam p.image-date::first-letter{
	text-transform: capitalize;
}

div.articles article.webcam p.view img.view{
	width: 100%;
	max-height: 500px;
	cursor: pointer;
}

div.articles article.webcam p.view span.fullscreen{
	width: 30px;
	height: 30px;
	background: url('../images/fullscreen.svg');
	position: absolute;
	bottom: 10px;
	right: 10px;
	display: block;
	cursor: pointer;
	opacity: 0.75;
}


div.articles article.webcam p.view:hover span.fullscreen{
	opacity: 1;
}


/*------------------------------- Forms -------------------------------*/

article section button,
article section button.submit{
	background: var(--tab-button-color);
    height: calc(var(--tab-button-height) - 2px);
    display: flex;
    align-items: center;
    gap: 10px;
	color: white;
    margin: 15px 0 5px 0;
	border: 1px solid rgb(100,100,100);
	width: calc(100% - 2px);
}

article section button.submit{
    background-image: url(../images/validate.svg);
    background-repeat: no-repeat;
    background-position: center right 4px;
	background-size: 30px;
}

article section button.submit span{
	flex-grow: 1;
	padding-left: 15px;
	text-align: left;
}

article section button img{
	height: calc(var(--tab-button-height) - 4px);
	margin-right: 5px;
}

article section button.submit img{
	height: var(--tab-button-height);
	display: none;
}

.settings input[type="number"],
.donate-window input[type="number"],
article section input[type="text"],
article section input[type="email"],
article section input[type="password"]{
	background-repeat: no-repeat;
	background-position: left;
	color: white;
	box-shadow: 0 0  2px rgb(150,150,150);
	background-color: transparent;
	border-radius: 30px;
	height: 32px;
	display: inline-flex;
	align-items: center;
	padding: 0 20px 0 35px;
	white-space: nowrap;
	width: 100%;
	margin-bottom: 5px;
	opacity: 1;
}

.settings input[type="number"],
.donate-window input[type="number"]{
	margin-top: 30px;
	background-image: url(../images/euro.svg);
	background-position: left 5px center;
}

.donate-window input[type="number"]+span{
	font-size: 0.8rem;
	opacity: 0.75;
}

article section input[type="text"]:focus,
article section input[type="email"]:focus,
article section input[type="password"]:focus{
	box-shadow: 0 0  2px white;
}

article section input[type="email"]{
	background-image: url(../images/email.svg);
}

article section input[type="password"]{
	background-image: url(../images/password.svg);
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px var(--section-gray) inset !important;
}

input:-webkit-autofill{
    -webkit-text-fill-color: white !important;
}

article section p span.annotation{
    color: grey;
    font-size: 0.85rem;
    line-height: 1rem;
    display: block;
    padding: 5px 10px 5px 10px;
}


/*-----------------------------------------------------------------------------------------------*/
/* LAYOUT                                                                                        */
/*-----------------------------------------------------------------------------------------------*/

article{
	padding: 0 10px 50px 10px;
	overflow: hidden;
}

div.scroller > article{
	padding-top: 0;
}

div[data-index] > article{
	padding-top: 0;
}

ul.selector:empty{
	display: none;
}

article section{
	padding: 20px 10px 20px 10px;
	margin-bottom: 10px;
	background: var(--section-gray);
	overflow: hidden;
}

div.scroller{
	display: flex;
	flex-direction: row;
	gap: 20px;
}

*[data-index] div > div.scroller{
	gap: 0;
}

article > div.scroller{
	margin: 0 -10px 0 -10px;
	padding: 0 10px 0 10px;
}

div.scroller > *{
    min-width: 100%;
	height: fit-content;
}

div.scroller{
	transform: translateX(calc(-100% * var(--index)));
}

div.scroller > *.hidden{
	overflow: hidden;
	height: 0;
	opacity: 0;
}

article.track img.loading{
	background-image: url(../images/loading-small.svg);
	background-repeat: no-repeat;
	background-size: 50px;
	background-position: center center;
}


/*-----------------------------------------------------------------------------------------------*/
/* DESKTOP                                                                                       */
/*-----------------------------------------------------------------------------------------------*/

html.desktop div.articles{
    max-width: var(--hamburger-menu-width);
    /* padding: 0 5px 0 0; */
}

html.desktop div.update-window div.actions button:hover,
html.desktop div.startup-messages div.actions button:hover,
article section input[type="file"]+label:hover,
html.desktop ul.selector > li > input+label:hover,
html.desktop ul.filter > li > input:not(:checked)+label:hover,
html.desktop article section > ul.actions > li:hover,
html.desktop article section > p.links > a.external:hover,
html.desktop article section > ul.data > li:hover{
	border-color: rgb(var(--snowmap-blue));
}
	
html.desktop ul.filter > li > input:not(:checked)+label:hover{
	border-color: rgba(var(--snowmap-blue), 0.75);
}


/*-----------------------------------------------------------------------------------------------*/
/* STATES                                                                                        */
/*-----------------------------------------------------------------------------------------------*/

body[data-articles] div.articles{
	opacity: 1;
	pointer-events: auto;
}

body[data-articles] div.articles > *{
	opacity: 1;
}


/*-----------------------------------------------------------------------------------------------*/
/* ANIMATIONS                                                                                    */
/*-----------------------------------------------------------------------------------------------*/

div.scroller{
	transition-property: transform;
	transition-duration: 0.5s;
	transition-timing-function: cubic-bezier(0.2, 0, 0.2, 1);
}

div.articles{
	transition-property: opacity;
	transition-duration: 0.25s;
	transition-timing-function: cubic-bezier(0.2, 0, 0.2, 1);
}

div.articles > *{
	transition-property: opacity;
	transition-duration: 0.25s;
	transition-timing-function: cubic-bezier(0.2, 0, 0.2, 1);
	transition-delay: 0s;
}

body[data-articles] div.articles{
	transition-delay: 0s;
}

article section > ul.photos > li{
	transition-property: margin;
	transition-duration: 0.25s;
	z-index: 2;
	background-color: var(--tab-button-color);
}



article{
    --font-size: 2.4rem;
}

article .text h1, 
article .text h2, 
article .text h3, 
article .text h4, 
article .text h5, 
article .text h6 {
	font-size: calc(var(--font-size) * var(--factor));
	line-height: calc(var(--font-size) * var(--factor));
	padding: 0;
	margin: 0;
	justify-content: left;
	height: auto !important;
}

article .text h2::before{
	display: none !important;
}



article .text h1 {
    font-weight: 200;
    --factor: 1;
}

article .text h2 {
    font-weight: 350;
    --factor: 0.9;
}

article .text h3 {
    font-weight: 500;
    --factor: 0.8;
}

article .text h4 {
    font-weight: 650;
    --factor: 0.7;
}

article .text h5 {
    font-weight: 800;
    --factor: 0.6;
}

article .text h6 {
    font-weight: 1000;
    --factor: 0.5;
}