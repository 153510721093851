div#map{
    --draw-point-size: 16px;
    --draw-edit-point-size: 8px;
}

div#map div.leaflet-marker-pane img.draw{
    border-radius: 100%;
    margin-right: 0;
    margin-bottom: 0;
    width: var(--draw-point-size);
    height: var(--draw-point-size);
    margin-top: calc(var(--draw-point-size) * -0.5);
    margin-left: calc(var(--draw-point-size) * -0.5);
    z-index: 99999999;
}

div#map div.leaflet-marker-pane img.moveHandle{
    background: radial-gradient(circle,
        black 0%,
        black 35%,
        yellow 45%,
        yellow 55%,
        black 65%,
        black 100%
    );
    cursor: pointer;
    z-index: 99999999;
}

div#map div.leaflet-marker-pane img.lastMoveHandle{
    background: radial-gradient(circle,
        #b2ff00 0%,
        #b2ff00 25%,
        black 35%,
        black 40%,
        yellow 50%,
        yellow 100%
    );
    z-index: 99999999;
}

div#map div.leaflet-marker-pane img.addHandle{
    background: radial-gradient(circle,
        yellow 0%,
        yellow 45%,
        black 55%,
        black 100%
    );
    z-index: 99999999;
}

div#map div.leaflet-marker-pane img.addHandle{
    width: var(--draw-edit-point-size);
    height: var(--draw-edit-point-size);
    margin-top: calc(var(--draw-edit-point-size) * -0.5);
    margin-left: calc(var(--draw-edit-point-size) * -0.5);
    cursor: pointer;
}

div#map path.track{
    stroke: yellow;
    stroke-opacity: 100%;
    stroke-width: 4px;
    fill: none;
    z-index: 99999999;
}

body:not([data-draw="edited"], [data-draw="started"]) div#map path.track.hover:not(.selected),
body:not([data-draw="edited"], [data-draw="started"])  div#map path.track:hover:not(.selected){
    stroke: #ffe200;
}

body[data-draw="edited"] path{
    pointer-events: none;
    cursor: grab;
}

div#map path.pathClone{
    stroke-opacity: 0%;
    stroke-width: 20px;
    fill: none;
    z-index: 99999998;
}

body:not([data-draw="edited"]) div#map path.track.selected{
    stroke: #00c3ff;
    stroke-width: 6px;
    z-index: 99999999;
}

div#map.track div#map div.leaflet-marker-pane img:not(.draw){
    pointer-events: none;
}

div#map.track div#map img.icon.regions{
    display: none;
}