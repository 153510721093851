.leaflet-marker-icon div.aim {
	display: none;
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 1;
	background-position: center center;
	background-size: 100%;
	background-repeat: no-repeat;
	pointer-events: none;
	--crosshair-size: 2px;
}

div.leaflet-zoom-anim .leaflet-marker-icon div.aim {
	display: none !important;
}

body[data-location="true"] .leaflet-marker-icon div.aim ,
body[data-snowlayer] .leaflet-marker-icon div.aim {
	display: block;
}

.leaflet-marker-icon div.aim div{
	display: block;
	position: absolute;
	backdrop-filter: invert(100%) saturate(0%);
}

body.leaflet-dragging .leaflet-marker-icon div.aim div{
	background: black;
	box-shadow: 0 0 0 1px white;
	opacity: 1;
}

.leaflet-marker-icon div.aim div:nth-child(3){
	width: 2px;
	height: 4px;
	top: calc(-5px - 2 * var(--crosshair-size));
	left: -1px;
}

.leaflet-marker-icon div.aim div:nth-child(2){
	width: 4px;
	height: 2px;
	top: -1px;
	left: calc(0px + 2 * var(--crosshair-size));
}

.leaflet-marker-icon div.aim div:nth-child(4){
	width: 2px;
	height: 4px;
	top: calc(1px + 2 * var(--crosshair-size));
	left: -1px;
}

.leaflet-marker-icon div.aim div:nth-child(1){
	width: 4px;
	height: 2px;
	top: -1px;
	left: calc(-5px - 2 * var(--crosshair-size));
}