ul.breadcrumbs{
    position: absolute;
    top: 60px;
    right: 0px;
    left: 0px;
    overflow-x: scroll;
    touch-action: pan-x;
    padding: 0 10px 0 10px;
    display: flex;
    gap: 10px;
    white-space: nowrap;
	pointer-events: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

ul.breadcrumbs::-webkit-scrollbar {
    display: none;
}

ul.breadcrumbs > li{
    background: var(--black1);
    border-radius: 25px;
    padding: 5px 15px 5px 15px;
    font-size: 1rem;
    height: var(--breadcrumbs-height);
    line-height: var(--breadcrumbs-height);
    cursor: pointer;
	pointer-events: auto;
}

ul.breadcrumbs > li.removeable::before{
    content: "\00D7";
    font-size: 1.75rem;
    display: inline-block;
    line-height: 0;
    vertical-align: -1.5px;
    margin: 0 10px 0 -5px;
}


/*-----------------------------------------------------------------------------------------------*/
/* DESKTOP                                                                                       */
/*-----------------------------------------------------------------------------------------------*/

html.desktop ul.breadcrumbs{
    left: calc(var(--hamburger-menu-width) + 60px);
    top: calc(var(--map-buttons-height) / 2);
    
	transition-property: left;
	transition-duration: 0.5s;
	transition-timing-function: cubic-bezier(0.2, 0, 0.2, 1);
}


html.desktop body[data-hamburger="open"] ul.breadcrumbs{
    left: 60px;
}