div.map div.map-attributions {
    width: 20px;
    height: 20px;
    background-color: var(--black1);
    background-image: url(../images/information.svg);
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 7px;
    box-shadow: 0 0 0 1px rgb(255 255 255 / 50%);
    font-size: 0.75rem;
    display: flex;
    justify-content: right;
    align-items: center;
    color: rgba(0,0,0,0)
}

html.mobile div.map div.map-attributions {
    flex-wrap: wrap;
    gap: 5px;
}

html.mobile div.map div.map-attributions {
    top: 170px;
    right: 25px;
}



div.map div.map-attributions.open {
    width: auto;
    height: 20px;
    color: white;
    padding: 0 5px 0 5px;
    background-image: none;
    z-index: 9999;
}


html.mobile div.map div.map-attributions.open {
    background: none;
    box-shadow: none;
    padding: 0;
}

div.map div.map-attributions span{
    padding: 0 10px 0 10px;
    display: none;
}

html.mobile div.map div.map-attributions span{
    background-color: var(--black1);
    padding: 3px 7px 5px 7px;
    border-radius: 7px;
    box-shadow: 0 0 0 1px rgb(255 255 255 / 50%);
}

div.map div.map-attributions.open span{
    display: block;
}

html.desktop div.map div.map-attributions.open span{
    white-space: nowrap;
}

div.map div.map-attributions span::before{
    content: '\00a9';
    margin-right: 5px;
} 

html.desktop div.map div.map-attributions span:not(:last-child){
    border-right: 1px solid #acacac;
}