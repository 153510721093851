div.info-popup{
    position: absolute;
    bottom: 0;
    left: 0;
    right: -1000px;
    height: 50px;
    z-index: 999;
}

div.info-popup,
div.info-popup *{
    pointer-events: none;
}

html.mobile div.info-popup{
    bottom: 150px;
}

div.info-popup.open p{
    transform:
        translateX(-50%)
        translateY(0)
    ;
}

html.mobile div.info-popup p{
    opacity: 0;
}

html.mobile div.info-popup.open p{
    opacity: 1;
}

div.info-popup.close-fast p{
    transition-duration: 0.25s;
}

div.info-popup p{
    position: absolute;
    left: 50vw;
    transform:
        translateX(-50%)
        translateY(100%)
    ;
    transition-property: transform, opacity;
    transition-duration: 0.5s;
    bottom: 0;
    background: var(--black1);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-repeat: no-repeat;
    background-position: center left 10px;
    background-size: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px 20px 10px 20px;
    box-shadow: 0 0  1px #a3a3a3;
    backdrop-filter: blur(10px);
    color: white;
    margin-top: 1px;
    width: auto;
}

html.mobile div.info-popup p{
    max-width: calc(100vw - 20px);
    border-radius: 1000px;
}

div.info-popup p img {
    width: 1.5rem;
}

html.mobile div.info-popup p img {
    width: 4rem;
}