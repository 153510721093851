article.print{
    background: white;
	color: black;
}

article.print *{
	color: black;
}

article.print *{
	margin: 0;
	padding: 0;
	font-weight: normal;
	font-size: 13px;
	font-family: sans-serif;
}

article.print ul,
article.print ol{
	list-style-position: outside;
	margin-top: 2rem;
    margin-left: 1.5rem;
}

article.print p{
	margin-top: 1rem;
}

article.print h2{
	font-size: 2rem;
	font-weight: 800;
}

article.print h3{
	margin-top: 1.5rem;
	font-size: 1.5rem;
	font-weight: 600;
}

article.print h4{
	margin-top: 1.25rem;
	font-size: 1.25rem;
	font-weight: 500;
}