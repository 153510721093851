div.map-info{
    position: absolute;
    bottom: 85px;
    right: 0;
    left: 0;
    pointer-events: none;
    transition-property: opacity;
    transition-duration: 0.5s;
}

body[data-tooltip="open"] div.map-info,
body[data-draw] div.map-info{
    opacity: 0;
    transition-duration: 0s;
}

html.desktop div.map-info{
    bottom: 160px;
}

div.map-info ul{
    display: flex;
    gap: 20px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

body div.map-info ul li{
    display: none;
    flex-direction: row-reverse;
    justify-content: right;
    align-items: center;
    padding-left: 10px;
    border-radius: 100px;
    background-color: var(--black1);
    cursor: pointer;
    width: 85px;
    height: 20px;
    overflow: hidden;
    position: relative;
}

body div.map-info ul li.visible{
    display: flex;
}

div.map-info ul li span{
    flex-grow: 1;
    text-align: center;
    white-space: nowrap;
}

div.map-info ul li img{
    height: 150%;
    border-radius: 100%;
    margin: 0 -5px 0 -2px;
}

div.map-info ul li[data-type="distance"] img{
    height: 100%;
    margin: 0;
}

body[data-location="true"] div.map-info ul li.visible[data-type="altitude"],
body[data-location="true"] div.map-info ul li.visible[data-type="distance"],
body[data-location="true"] div.map-info ul li.visible[data-type="orientation"]{
    display: flex;
}

div.map-info ul li.visible[data-type="altitude"],
div.map-info ul li.visible[data-type="orientation"],
div.map-info ul li.visible[data-type="distance"]{
    display: none;
}

body:not([data-snowLayer]) div.map-info ul li[data-type="snow"]{
    display: none;
}

div.map-info ul li[data-type="snow"] img{
    background-color: var(--color);
    aspect-ratio: 1;
    height: calc(100% - 2px);
    margin: 0 1px 0 0;
    object-position: -999px;
    box-shadow: inset 0 0 0 1px rgba(255,255,255, 0.25);
}