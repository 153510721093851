div.legend{
    position: absolute;
    right: 20px;
    top: 170px;
    bottom: 105px;
    z-index: 0;
    opacity: 0;
    pointer-events: none;
}

div.legend ul{
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    justify-content: space-between;
    list-style: none;
    width: 8px;
    align-items: end;
    overflow: visible;
    border-radius: 100px;
    border: 1px solid rgba(0,0,0,0.4);
    background-repeat: no-repeat;
}

div.legend ul li{
    width: fit-content;
    margin-right: -4px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
    flex-direction: row;
    height: 19px;
}

div.legend ul li span{
    background: rgba(0,0,0,0.75);
    border-radius: 100px;
    padding: 2px 5px 2px 5px;
}

div.legend ul li img{
    width: 40px;
    filter: drop-shadow(0 0 5px rgba(0,0,0,0.25));
}

div.legend ul li:after{
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: var(--color);
    display: block;
    content: "";
    border: 1px solid rgba(0,0,0,0.4);
}


/*--------------------------------------------------------------------*/
/* Desktop                                                            */
/*--------------------------------------------------------------------*/

html.desktop div.legend{
    top: 100px;
    bottom: 150px;
}


/*--------------------------------------------------------------------*/
/* States                                                             */
/*--------------------------------------------------------------------*/

body[data-snowLayers] div.legend{
    opacity: 1;
}

div.legend[data-visibility]{
    opacity: 0;
}


/*--------------------------------------------------------------------*/
/* Animations                                                         */
/*--------------------------------------------------------------------*/

div.legend{
    transition-property: opacity;
    transition-duration: 0.25s;
}