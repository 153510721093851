div.donate-window,
div.update-window{
    backdrop-filter: blur(10px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
}

div.donate-window > div.texts,
div.update-window > div.texts{
    display: none !important;
}

div.donate-window > div,
div.update-window > div{
    width: 500px;
    background: var(--section-gray);
    margin: 50vh auto 0 auto;
    transform: translate(0, -50%);
    padding: 30px;
    position: relative;
    border-radius:  5px;
    box-shadow: 0 0 15px 0px rgba(0,0,0,0.5);
}

html.mobile div.donate-window,
html.mobile div.update-window{
    padding: 10px;
}

html.mobile div.donate-window > div,
html.mobile div.update-window > div{
    width: 100%;
}

html.mobile div.donate-window > div div.scroll,
html.mobile div.update-window > div div.scroll{
    flex-grow: 1;    
}

div.donate-window > div.hidden,
div.update-window > div.hidden{
    opacity: 0;
}

div.donate-window > div div.scroll,
div.update-window > div div.scroll{
    overflow: hidden;
    background: rgba(255,255,255,0.03);
    padding: 30px;
    /* height: 200px; */
    max-height: calc(100vh - 200px);
    overflow-y: scroll;
}

html.mobile div.donate-window > div div.scroll::-webkit-scrollbar ,
html.mobile div.update-window > div div.scroll::-webkit-scrollbar {
	display: block;
}

div.donate-window p{
    line-height: 1.75rem;
}

div.donate-window p+p{
    margin-top: 1rem;
}

div.donate-window ul.actions{
	display: flex;
	flex-direction: column;
	gap: 5px;
	padding: 10px 0 10px 0;
}

div.donate-window ul.actions li{
	background-color: var(--tab-button-color);
	border: 1px solid rgb(80, 80, 80);
	height: var(--tab-button-height);
	display: flex;
	align-items: center;
	gap: 10px;
	font-size: 1rem;
	flex-direction: row;
	color: white;
}

div.donate-window ul.actions li img{
	height: var(--tab-button-height);
}

div.donate-window h1,
div.update-window h1{
    font-size: 3rem;
    font-weight: 200;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0 20px 0 20px;
}

html.mobile div.donate-window h1,
html.mobile div.update-window h1{
    font-size: 2.5rem;
}

html.mobile div.donate-window h1,
html.mobile div.update-window h1{
    padding: 0;
}

div.donate-window h1 img,
div.update-window h1 img{
    height: 50px;
}

div.donate-window div.actions,
div.update-window div.actions{
    background: var(--section-gray);
    margin-top: 20px;
    display: flex;
    gap: 10px;
    flex-direction: row;
}

div.donate-window div.actions button,
div.update-window div.actions button{
    width: 100%;
    text-align: center !important;
}

div.donate-window div.actions button img,
div.update-window div.actions button img{
    height: 100%;
}