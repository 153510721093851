div#map{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
}

div#map > .leaflet-control-container {
    display: none;
}

#map .leaflet-map-pane{
    z-index: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: grab !important;
}

#map .leaflet-drag-target,
#map .leaflet-drag-target *{
    cursor: grabbing !important;
}

html.desktop div#map{
    z-index: 0;
}

div#map > div.filters-button{
    opacity: 0;
    pointer-events: none;
}
    
div#map > div.filters-button > button{
    position: absolute;
    background: rgba(0,0,0,0.75);
    color: white;
    background-image: url('../images/filters.svg');
    background-repeat: no-repeat;
    background-position: center left 7px;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    padding: 7px 17px 7px 40px;
    border-radius: 100px;
    z-index: 2;
    cursor: pointer;
}

body[data-poiLayers] div#map > div.filters-button{
    opacity: 1;
    pointer-events: auto;
}

div#map.zooming path{
    display: none;
}

div#map.zooming .icon.regions{
    display: none;
}