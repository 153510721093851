div.reportsList{
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
    padding-bottom: 80px;
    padding: 60px 0 50px 0;
}

div.reportsList ul.countries_list{
	display: flex;
	gap: 20px;
	flex-direction: column;
	padding: 30px 20px 20px 20px;
	/* margin: 30px 0 30px 0; */
	list-style: none;
}

div.reportsList ul.countries_list li{
	display: flex;
	/* flex-grow: 1; */
	align-items: center;
	border: 1px solid rgb(100,100,100);
	background: var(--tab-button-color);
	border-radius: 100px;
	height: 45px;
	line-height: 25px;
	width: 100%;
	gap: 20px;
	padding-left: 20px;
}

html.desktop div.reportsList ul.countries_list li:hover{
	border: 1px solid rgb(var(--snowmap-blue));
}

div.reportsList ul.countries_list li span{
    flex-grow: 1;
}

div.reportsList ul.countries_list li img{
    flex-grow: 0;
    width: 50px;
    aspect-ratio: 1;
    border-radius: 100%;
    border: 5px solid #1e1e1e;
    margin-right: -2px;
}

div.reportsList ul.providers{
	padding: 20px 10px 30px 10px;
	display: flex;
	width: 100%;
	list-style: none;
	gap: 20px;
	flex-wrap: wrap;
	justify-content: center;
}

div.reportsList ul.providers a{
	color: white;
	text-decoration: none;
}

div.reportsList ul.providers li,
div.reportsList ul.providers li a{
	height: 30px;
	line-height: 25px;
	gap: 10px;
	display: flex;
	font-size: 0.85rem;
	align-items: center;
}


div.reportsList ul.providers li img{
	height: 30px;
}

div.reportsList[data-mode="countries"] .countries{
    display: block;
}

div.reportsList[data-mode="countries"] section.regions{
    display: none;
}

div.reportsList[data-mode="regions"] .countries{
    display: none;
}

div.reportsList[data-mode="regions"] section.regions{
    display: block;
}

div.bookmarks article.regions section p,
div.reportsList p.icons{
	display: flex;
	padding-bottom: 10px;
}


div.bookmarks article.regions section p img,
div.reportsList p.icons > span > img{
	width: 100%;
}

div.bookmarks article.regions section p span,
div.reportsList p.icons > span{
    flex-grow: 1;
    flex-basis: 0;
    flex-shrink: 0;
}

div.bookmarks article.regions section h2,
div.reportsList h2{
    padding: 0;
}

div.bookmarks article.regions section h4.warning,
div.reportsList h4{
    font-size: 1rem;
    justify-content: end;
    display: flex;
    opacity: 0.5;
}

div.reportsList h4:empty{
	display:none;
}

div.reportsList h4.warning,
div.bookmarks article.regions section h4.warning{
    color: red;
}

div.bookmarks article.regions section h4.warning::before,
div.reportsList h4::before{
    content: '';
    width: 2rem;
    display: inline-block;
    aspect-ratio: 1;
    background-image: url('../images/clock.svg');
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 10px;
    flex-shrink: 0;
}

div.bookmarks article.regions section h4.warning,
div.reportsList h4.warning{
    opacity: 1;
    font-weight: 600;
    text-decoration: underline;
}

div.bookmarks article.regions section h4.warning::before,
div.reportsList h4.warning::before{
    width: 3rem;
    background-image: url('../images/exposure.svg');
}

div.reportsList section.regions.blank{
    display: none;
}

div.reportsList ul.filter li.blank{
    display: none;
}

div.bookmarks article.regions section:hover,
html.desktop div.reportsList section[data-region]:hover{
    background-color: rgba(255,255,255,0.15);
    border-color: rgb(var(--snowmap-blue));
    box-shadow: inset -5px 0 0 rgb(var(--snowmap-blue));
}