div.bookmarks article{
    display: none;
}

div.bookmarks .blank{
    display: none !important;
}

div.bookmarks[data-mode="root"] article.root{
    display: block;
}

div.bookmarks[data-mode="tracks"] article.tracks{
    display: block;
}

div.bookmarks[data-mode="regions"] article.regions{
    display: block;
}

div.bookmarks[data-mode="sorties"] article.sorties{
    display: block;
}

div.bookmarks[data-mode="topos"] article.topos{
    display: block;
}

div.bookmarks[data-mode="refuges"] article.refuges{
    display: block;
}

div.bookmarks[data-mode="stations"] article.stations{
    display: block;
}

div.bookmarks[data-mode="webcams"] article.webcams{
    display: block;
}

div.bookmarks article:not(.root) ul,
div.bookmarks ul.categories {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-top: 20px;
}

div.bookmarks ul li ul.actions {
    margin-top: 0;
}

div.bookmarks article:not(.root) ul li,
div.bookmarks ul.categories li{
    display: flex;
    align-items: center;
    gap: 15px;
    height: 70px;
    border-bottom: 1px solid grey;
    cursor: pointer;
    padding-left: 5px;
}
div.bookmarks article:not(.root) ul li{
    padding-right: 5px
}

div.bookmarks ul li,
div.bookmarks ul li *{
    cursor: pointer;
}

div.bookmarks ul li div ul li img{
    filter: drop-shadow(0.5px 0.5px 0 white);
}

div.bookmarks article:not(.root) section > ul > li:hover,
html.desktop div.bookmarks ul.categories > li:hover{
    background: rgba(255, 255, 255, 0.05);
    box-shadow: inset -5px 0 0 rgb(var(--snowmap-blue));
}

div.bookmarks article:not(.root) ul li img,
div.bookmarks ul.categories li img {
    width: 35px;
    aspect-ratio: 1;
    border-radius: 100%;
}

div.bookmarks article:not(.root) ul li p,
div.bookmarks ul.categories li p {
    padding: 0;
    gap: 3px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 1.1rem;
}

div.bookmarks article:not(.root) ul li p{
    display: block;
}

div.bookmarks ul.categories li p {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
}

div.bookmarks article:not(.root) ul li span,
div.bookmarks ul.categories li span {
    line-height: 0.9rem;
}

div.bookmarks article:not(.root) ul li span.info,
div.bookmarks ul.categories li span.info {
    font-size: 0.9rem;
    color: #bababa;
}

div.bookmarks article:not(.root) ul li div{
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex-grow: 1;
    padding-top: 5px;
    flex-shrink: 1;
    flex-basis: auto;
    overflow: hidden;
}

div.bookmarks article:not(.root) ul li div ul{
    display: flex;
    flex-direction: row;
    font-size: 0.9rem;
    opacity: 0.75;
    margin-top: -5px;
    flex-wrap: wrap;
    height: 20px;
}

div.bookmarks article:not(.root) ul li div ul li{
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    height: auto;
    border: none;
    padding: 0;
}

div.bookmarks article:not(.root) ul li div ul li img{
    outline: none;
    border-radius: 0;
    width: 20px;
}

div.bookmarks article:not(.root) ul li span{
    margin: 0 7px 0 -4px;
}

div.bookmarks article:not(.root) ul ul.actions{
    display: flex;
    flex-direction: row;
}

div.bookmarks article:not(.root) ul ul.actions li{
    border: none;
}

div.bookmarks article:not(.root) ul ul.actions img{
    outline: none;
    background: rgba(255, 255, 255, 0.125);
}

div.bookmarks article:not(.root) ul ul.actions img:hover{
    background: rgba(255, 255, 255, 0.25)
}

div.bookmarks article:not(.root) ul ul.actions input[type="checkbox"]{
    display: none;
}

div.bookmarks article:not(.root) ul ul.actions input[type="checkbox"]+label{
    background: rgba(255, 255, 255, 0.125);
    background-image: url('../images/hide.svg');
    opacity: 0.5;
    
    width: 35px;
    aspect-ratio: 1;
    border-radius: 100%;
}

div.bookmarks article:not(.root) ul ul.actions input[type="checkbox"]:checked+label{
    opacity: 1;
    background-image: url('../images/show.svg');
}

div.bookmarks article:not(.root) ul ul.actions input[type="checkbox"]+label:hover{
    background-color: rgba(255, 255, 255, 0.25)
}