div.carousel{
    background: black;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
}

div.carousel,
div.carousel *{
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

div.carousel > ul.images{
    transition: transform, 0.5s cubic-bezier(0.2, 0.0, 0.1, 1.0);
    display: flex;
    transform: translateX(calc(-100% * var(--data-index)));
    position: absolute;
    top: 0px;
    bottom: 70px;
    left: 0;
    right: 0;
}

div.carousel > ul.images > li{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content:center;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 100%;
    overflow: hidden;
}

div.carousel > ul.images > li > img{
    width: 100%;
    height: 100%;
    object-position: -99999px -99999px;
    background-size: contain; /* This will cover the entire div */
    background-repeat: no-repeat; /* Prevent image from repeating */
    background-position: center; /* Center the image */
}

html.mobile div.carousel > ul.navigation{
	display: none;
}

div.carousel > ul.navigation > li,
div.carousel > ul.navigation > li{
    position: absolute;
    top: 0;
    bottom: 0;
    background: transparent;
    width: 100px;
    padding: 20px;
    display: flex;
}

div.carousel > ul.navigation > li > img{
    width: 100%;
    height: 100%;
}

html.desktop div.carousel > ul.navigation > li:hover{
    background: rgba(255,255,255,0.25);
}

div.carousel > ul.navigation > li.next{
    right: 0;
}

div.carousel > ul.navigation > li.previous{
    left: 0;
}

div.carousel div.actions{
	position: absolute;
	bottom: 130px;
	display: flex;
	left: 0;
	right: 0;
	align-items: center;
	justify-content: center;
	gap: 20px;
	display: none;
}

div.carousel div.actions button{
	width: var(--map-buttons-height);
	aspect-ratio: 1;
    background-color: var(--black1);
    box-shadow: 0 0  1px grey;
    border-radius: 100%;
}

div.carousel div.actions button.download{
    background-image: url(../images/download.svg);
	display: none;
}

div.carousel div.actions button.bookmark{
    background-image: url(../images/rating.svg);
	display: none;
	
}

div.carousel > div.title{
    position: absolute;
    bottom: 100px;
	display: flex;
	justify-content: center;
	left: 0;
	right: 0;
}

div.carousel > div.title p{
	backdrop-filter: brightness(50%) blur(10px);
	display: block;
	padding: 0.5rem 2rem 0.5rem 2rem;
	border: 1px solid rgba(255,255,255,0.25);
}

div.carousel > div.title p::first-letter{
	text-transform: capitalize;
}

div.carousel > ul.thumbnails{
    position: absolute;
    left: 0;
    bottom: 5px;
    right: 0;
    height: 60px;
    display: flex;
    gap: 10px;
    align-items: center;
    overflow-x: scroll;
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px 0 10px;
}

div.carousel > ul.thumbnails li{
    height: calc(100% - 2px);
    aspect-ratio: 1;
    background-size: cover;
    cursor: pointer;
    opacity: 0.5;
}

div.carousel > ul.thumbnails li.active{
	opacity: 1;
	box-shadow: 0 0  1px white;
}

html.desktop div.carousel > ul.thumbnails li:hover{
	opacity: 1;
}

/* States */
div.carousel[data-visibility="visible"]{
    opacity: 1;
    pointer-events: auto;
}

div.carousel ul.controls{
	display: none;
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	pointer-events: none;
	justify-content: space-between;
	z-index: 9999999999;
}

div.carousel ul.controls li{
	background-image: url(../images/arrow-left.svg);
	background-position: center center;
	width: 100px;
	height: 100px;
	transform: translateY(-50%);
	pointer-events: auto;
}

div.carousel ul.controls li:hover{
	background-size: 125%;
}

div.carousel ul.controls li:last-child{
	background-image: url(../images/arrow-right.svg);
}

html.desktop div.carousel ul.controls{
	display: flex;
}

