div.startup-messages{
    background: black;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
}

div.startup-messages > div.texts{
    display: none !important;
}

div.startup-messages > div{
    width: 50rem;
    background: var(--section-gray);
    margin: 50vh auto 0 auto;
    transform: translate(0, -60%);
    padding: 30px 10px 40px 10px;
    position: relative;
}

html.mobile div.startup-messages{
    padding: 10px;
}

html.mobile div.startup-messages > div{
    position: absolute;
    top: 10px;
    bottom: 10px;
    right: 10px;
    left: 10px;
    transform: none;
    width: auto;
    height: none;
    margin: 0;
    display: flex;
    flex-direction: column;
}

html.mobile div.startup-messages > div div.scroll{
    flex-grow: 1;    
}

div.startup-messages > div.hidden{
    opacity: 0;
}

div.startup-messages > div div.scroll{
    overflow-x: hidden;
    overflow-y: scroll;
    background: rgba(255,255,255,0.03);
    padding: 30px 20px 50px 20px;
    height: 400px;
}

html.mobile div.startup-messages > div div.scroll::-webkit-scrollbar {
	display: block;
}

div.startup-messages h1{
    font-size: 3rem;
    font-weight: 200;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0 20px 0 20px;
}

html.mobile div.startup-messages h1{
    padding: 0;
}

div.startup-messages h1 img{
    height: 50px;
}

div.startup-messages p{
    margin-bottom: 20px;
    line-height: 1.5rem;
}

div.startup-messages div.actions{
    background: var(--section-gray);
    margin-top: 20px;
    display: flex;
    gap: 10px;
    flex-direction: row;
}

div.startup-messages div.actions button{
    width: 100%;
    text-align: center !important;
}

div.startup-messages div.actions button img{
    height: 100%;
}