/*---------------------------------------------------------------------------------*/
/* TILES                                                                           */
/*---------------------------------------------------------------------------------*/

div#map .backgroundLayer {
    filter: saturate(100%);
    opacity: 1 !important;
    transition-property: filter, opacity;
    transition-duration: 1s, 1s;
}

body[data-snowlayer]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"], [data-snowlayer="avalanche_risk"]) div#map .backgroundLayer {
    filter: saturate(0%);
}

div#map .snowLayer {
    mix-blend-mode: multiply;
}

div#map .snowLayer.slopes {
    mix-blend-mode: multiply;
    opacity: 0.5 !important;
}

div#map .snowLayer.itineraries{
    filter: invert(22%) sepia(92%) saturate(7482%) hue-rotate(360deg) brightness(102%) contrast(118%);
    mix-blend-mode: normal !important;
    opacity: 1 !important;
}

body[data-snowlayer][data-zoom="0"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer,
body[data-snowlayer][data-zoom="1"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer,
body[data-snowlayer][data-zoom="2"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer,
body[data-snowlayer][data-zoom="3"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer,
body[data-snowlayer][data-zoom="4"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer,
body[data-snowlayer][data-zoom="5"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer,
body[data-snowlayer][data-zoom="6"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer,
body[data-snowlayer][data-zoom="7"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer,
body[data-snowlayer][data-zoom="8"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer,
body[data-snowlayer][data-zoom="9"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer,
body[data-snowlayer][data-zoom="10"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer,
body[data-snowlayer][data-zoom="11"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer,
body[data-snowlayer][data-zoom="12"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer,
body[data-snowlayer][data-zoom="13"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer,
body[data-snowlayer][data-zoom="14"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer,
body[data-snowlayer][data-zoom="15"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer,
body[data-snowlayer][data-zoom="16"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer,
body[data-snowlayer][data-zoom="17"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer,
body[data-snowlayer][data-zoom="18"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer {
    filter: saturate(0) contrast(1) brightness(1);
}

body[data-snowlayer][data-zoom="0"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer.opentopo,
body[data-snowlayer][data-zoom="1"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer.opentopo,
body[data-snowlayer][data-zoom="2"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer.opentopo,
body[data-snowlayer][data-zoom="3"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer.opentopo,
body[data-snowlayer][data-zoom="4"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer.opentopo,
body[data-snowlayer][data-zoom="5"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer.opentopo,
body[data-snowlayer][data-zoom="6"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer.opentopo,
body[data-snowlayer][data-zoom="7"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer.opentopo,
body[data-snowlayer][data-zoom="8"]:not([data-snowlayer="itineraries"], [data-snowlayer="slopes"]) div#map .backgroundLayer.opentopo{
    filter: saturate(0) contrast(0.4) brightness(1.33);
}

body[data-snowlayer][data-zoom="12"][data-snowlayer="avalanche_risk"] div#map .backgroundLayer,
body[data-snowlayer][data-zoom="13"][data-snowlayer="avalanche_risk"] div#map .backgroundLayer,
body[data-snowlayer][data-zoom="14"][data-snowlayer="avalanche_risk"] div#map .backgroundLayer,
body[data-snowlayer][data-zoom="15"][data-snowlayer="avalanche_risk"] div#map .backgroundLayer,
body[data-snowlayer][data-zoom="16"][data-snowlayer="avalanche_risk"] div#map .backgroundLayer,
body[data-snowlayer][data-zoom="17"][data-snowlayer="avalanche_risk"] div#map .backgroundLayer,
body[data-snowlayer][data-zoom="18"][data-snowlayer="avalanche_risk"] div#map .backgroundLayer {
    filter: saturate(0) contrast(1) brightness(1) !important;
}

body[data-zoom="12"] div#map img.icon.regions,
body[data-zoom="13"] div#map img.icon.regions,
body[data-zoom="14"] div#map img.icon.regions,
body[data-zoom="15"] div#map img.icon.regions,
body[data-zoom="16"] div#map img.icon.regions,
body[data-zoom="17"] div#map img.icon.regions,
body[data-zoom="18"] div#map img.icon.regions {
    display: none;
}

body[data-zoom="12"] div#map path.regions:hover:not(.selected),
body[data-zoom="13"] div#map path.regions:hover:not(.selected),
body[data-zoom="14"] div#map path.regions:hover:not(.selected),
body[data-zoom="15"] div#map path.regions:hover:not(.selected),
body[data-zoom="16"] div#map path.regions:hover:not(.selected),
body[data-zoom="17"] div#map path.regions:hover:not(.selected),
body[data-zoom="18"] div#map path.regions:hover:not(.selected),
body[data-zoom="12"] div#map path.regions:not(.selected),
body[data-zoom="13"] div#map path.regions:not(.selected),
body[data-zoom="14"] div#map path.regions:not(.selected),
body[data-zoom="15"] div#map path.regions:not(.selected),
body[data-zoom="16"] div#map path.regions:not(.selected),
body[data-zoom="17"] div#map path.regions:not(.selected),
body[data-zoom="18"] div#map path.regions:not(.selected) {
    opacity: 0;
}

body[data-zoom="12"] div#map path.regions.selected:hover,
body[data-zoom="13"] div#map path.regions.selected:hover,
body[data-zoom="14"] div#map path.regions.selected:hover,
body[data-zoom="15"] div#map path.regions.selected:hover,
body[data-zoom="16"] div#map path.regions.selected:hover,
body[data-zoom="17"] div#map path.regions.selected:hover,
body[data-zoom="18"] div#map path.regions.selected:hover {
    opacity: 1;
}

body[data-zoom="1"] div#map div.leaflet-marker-pane img.icon.regions,
body[data-zoom="2"] div#map div.leaflet-marker-pane img.icon.regions,
body[data-zoom="3"] div#map div.leaflet-marker-pane img.icon.regions,
body[data-zoom="4"] div#map div.leaflet-marker-pane img.icon.regions,
body[data-zoom="5"] div#map div.leaflet-marker-pane img.icon.regions {
    --avalanche_risk-icon-size: 15px;
}

body[data-zoom="6"] div#map div.leaflet-marker-pane img.icon.regions {
    --avalanche_risk-icon-size: 25px;
}

body[data-zoom="7"] div#map div.leaflet-marker-pane img.icon.regions {
    --avalanche_risk-icon-size: 35px;
}

body[data-zoom="8"] div#map div.leaflet-marker-pane img.icon.regions {
    --avalanche_risk-icon-size: 40px;
}

div#map div.leaflet-marker-pane img.icon.regions {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: calc(var(--avalanche_risk-icon-size) * 0.15) center;
    margin-left: calc(var(--avalanche_risk-icon-size) * -0.5);
    margin-top: calc(var(--avalanche_risk-icon-size) * -0.5);
    width: calc(var(--avalanche_risk-icon-size));
}

div#map div.leaflet-marker-pane img.icon.regions.risquenull,
div#map div.leaflet-marker-pane img.icon.regions.risque0 {
    background-image: url('../images/risque0.svg');
}

div#map div.leaflet-marker-pane img.icon.regions.risque1 {
    background-image: url('../images/risque1.svg');
}

div#map div.leaflet-marker-pane img.icon.regions.risque2 {
    background-image: url('../images/risque2.svg');
}

div#map div.leaflet-marker-pane img.icon.regions.risque3 {
    background-image: url('../images/risque3.svg');
}

div#map div.leaflet-marker-pane img.icon.regions.risque4 {
    background-image: url('../images/risque4.svg');
    background-position: center;
}

div#map div.leaflet-marker-pane img.icon.regions.risque5 {
    background-image: url('../images/risque5.svg');
    background-position: center;
}

div#map div.leaflet-marker-pane img.icon.searchResult {
    background-image: var(--search-result-gradient) !important;
}

div#map div.leaflet-marker-pane img.icon.searchResult.highlighted {
    background-image: var(--search-result-gradient-hover) !important;
    z-index: 9999 !important;
}

div#map div.leaflet-marker-pane img.icon.searchResult:hover {
    background-image: var(--search-result-gradient-hover) !important;
    z-index: 9;
}

div#map path.regions {
    fill: white;
    fill-opacity: 0;
    stroke: #FFFFFF;
    stroke-width: 2px;
}

div#map path.regions:hover,
div#map path.regions.hover {
    opacity: 1;
    stroke-width: 3px;
    stroke: #6c6c6c;
}

div#map path.regions.risk_1.hover,
div#map path.regions.risk_1.selected,
div#map path.regions.risk_1:hover {
    stroke: var(--risk1);
}

div#map path.regions.risk_2.hover,
div#map path.regions.risk_2.selected,
div#map path.regions.risk_2:hover {
    stroke: var(--risk2);
}

div#map path.regions.risk_3.hover,
div#map path.regions.risk_3.selected,
div#map path.regions.risk_3:hover {
    stroke: var(--risk3);
}

div#map path.regions.risk_4.hover,
div#map path.regions.risk_4.selected,
div#map path.regions.risk_4:hover {
    stroke: var(--risk4);
}

div#map path.regions.risk_5.hover,
div#map path.regions.risk_5.selected,
div#map path.regions.risk_5:hover {
    stroke: var(--risk5);
}

div#map path.regions.selected {
    opacity: 1;
    stroke-width: 3px;
}

div#map path.sorties,
div#map path.topos {
    stroke: yellow;
    stroke-opacity: 100%;
    stroke-width: 4px;
    fill: none;
}

div#map path.clone {
    stroke: red;
    stroke-opacity: 0%;
    stroke-width: 24px;
    fill: none;
}

div#map div.leaflet-marker-pane img.icon.searchResult,
div#map div.leaflet-marker-pane div.marker-cluster,
div#map div.leaflet-marker-pane img.poi {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-position: center;
    margin-left: calc(var(--poi-icon-size) * -0.5) !important;
    margin-top: calc(var(--poi-icon-size) * -0.5) !important;
    width: calc(var(--poi-icon-size)) !important;
    height: calc(var(--poi-icon-size)) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.75 !important;
    background-image: var(--poi-icon-gradient);
    color: black;
    border-radius: 100%;
    opacity: 0.75 !important;
    font-size: 0.8rem;
    font-weight: 680;
    cursor: pointer;
}

div#map div.leaflet-marker-pane div.marker-cluster:hover {
    background-image: var(--poi-icon-gradient-hover);
}

div#map div.leaflet-marker-pane div.marker-cluster * {
    cursor: pointer;
}

div#map div.leaflet-marker-pane img.poi.refuges {
    background-image: url('../images/refuge-black.svg'), var(--poi-icon-gradient);
    background-size: 80%, 100%;
    background-position: center;
}

div#map div.leaflet-marker-pane img.poi.sorties {
    background-image: url('../images/sortie-black.svg'), var(--poi-icon-gradient);
    background-size: 80%, 100%;
    background-position: center;
}

div#map div.leaflet-marker-pane img.poi.selected {
    opacity: 1 !important;
}

div#map div.leaflet-marker-pane img.poi.topos {
    background-image: url('../images/topo-black.svg'), var(--poi-icon-gradient);
    background-size: 80%, 100%;
    background-position: center;
}

div#map div.leaflet-marker-pane img.poi.topos:hover {
    background-image:
        url('../images/topo-black.svg'),
        var(--poi-icon-gradient-hover);
}

div#map div.leaflet-marker-pane img.poi.selected.topos {
    background-image:
        url('../images/topo.svg'),
        var(--poi-icon-gradient-reversed);
}

div#map div.leaflet-marker-pane img.poi.selected.topos:hover {
    background-image:
        url('../images/topo.svg'),
        var(--poi-icon-gradient-reversed-hover);
}

div#map div.leaflet-marker-pane img.poi.webcams {
    background-image: url('../images/webcam-black.svg'), var(--poi-icon-gradient);
    background-size: 80%, 100%;
    background-position: center;
}

div#map div.leaflet-marker-pane img.poi.webcams:hover {
    background-image:
        url('../images/webcam-black.svg'),
        var(--poi-icon-gradient-hover);
}

div#map div.leaflet-marker-pane img.poi.selected.webcams {
    background-image:
        url('../images/webcam.svg'),
        var(--poi-icon-gradient-reversed);
}

div#map div.leaflet-marker-pane img.poi.selected.webcams:hover {
    background-image:
        url('../images/webcam.svg'),
        var(--poi-icon-gradient-reversed-hover);
}

div#map div.leaflet-marker-pane img.viewAngle {
    width: var(--offsetTest);
    height: var(--offsetTest);
    margin-top: calc(var(--offsetTest) * -0.5);
    margin-left: calc(var(--offsetTest) * -0.5);
    --offsetTest: max(250px, calc(((var(--zoom) - 12) * 500px) + 500px));
    pointer-events: none;
    z-index: -9999999 !important;
}

div#map div.leaflet-marker-pane img.poi.stations {
    background-image: url('../images/station-black.svg'), var(--poi-icon-gradient);
    background-size: 80%, 100%;
    background-position: center;
}

div#map div.leaflet-marker-pane img.poi.stations:hover {
    background-image:
        url('../images/station-black.svg'),
        var(--poi-icon-gradient-hover);
}

div#map div.leaflet-marker-pane img.poi.selected.stations {
    background-image:
        url('../images/station.svg'),
        var(--poi-icon-gradient-reversed);
}

div#map div.leaflet-marker-pane img.poi.selected.stations:hover {
    background-image:
        url('../images/station.svg'),
        var(--poi-icon-gradient-reversed-hover);
}

div#map div.leaflet-marker-pane img.poi.refuges:hover {
    background-image:
        url('../images/refuge-black.svg'),
        var(--poi-icon-gradient-hover);
}

div#map div.leaflet-marker-pane img.poi.sorties:hover {
    background-image:
        url('../images/sortie-black.svg'),
        var(--poi-icon-gradient-hover);
}

div#map div.leaflet-marker-pane img.poi.selected.refuges {
    background-image:
        url('../images/refuge.svg'),
        var(--poi-icon-gradient-reversed);
}

div#map div.leaflet-marker-pane img.poi.selected.sorties {
    background-image:
        url('../images/sortie.svg'),
        var(--poi-icon-gradient-reversed);
}

div#map div.leaflet-marker-pane img.poi.selected.refuges:hover {
    background-image:
        url('../images/refuge.svg'),
        var(--poi-icon-gradient-reversed-hover);
}

div#map div.leaflet-marker-pane img.poi.selected.sorties:hover {
    background-image:
        url('../images/sortie.svg'),
        var(--poi-icon-gradient-reversed-hover);
}

body[data-draw="edited"] img.icon.regions,
body[data-draw="started"] img.icon.regions,
body[data-draw="edited"] path.regions,
body[data-draw="started"] path.regions {
    display: none;
}

body[data-draw="edited"] div#map div.marker-cluster,
body[data-draw="started"] div#map div.marker-cluster,
body[data-draw="edited"] div#map img.poi,
body[data-draw="started"] div#map img.poi {
    pointer-events: none;
}

div#map .map-pointer {
    --map-pointer-size: 20px;
    box-shadow: 0 0 12px 2px white;
    width: var(--map-pointer-size) !important;
    height: var(--map-pointer-size) !important;
    margin: 
        calc(var(--map-pointer-size) * -0.5)
        0
        0
        calc(var(--map-pointer-size) * -0.5)
        !important;
    border-radius: 100%;
    display: block;
    overflow: visible;
    background-image: url('../images/aim.svg');
    transition-property: box-shadow;
    transition-duration: 0.5s;
}

@keyframes changewidth {
    0% {
        box-shadow: 0 0 0 0 white;
        animation-timing-function: cubic-bezier(0, 0.42, 0.59, 0.99);
    }

    15% {
        box-shadow: 
            0 0 calc(0.5 * var(--map-pointer-size)) 2px white,
            0 0 calc(0.5 * var(--map-pointer-size)) 2px white;
    }

    50% {
        box-shadow:
            0 0 var(--map-pointer-size) 2px rgba(255, 255, 255, 0),
            0 0 var(--map-pointer-size) 2px rgba(255, 255, 255, 0);
    }

    100% {
        box-shadow:
            0 0 var(--map-pointer-size) 2px rgba(255, 255, 255, 0),
            0 0 var(--map-pointer-size) 2px rgba(255, 255, 255, 0);
    }
}

body[data-location="false"] div#map .precision,
body[data-location="false"] div#map .map-pointer {
    display: none;
}

div#map .map-pointer {
    animation-duration: 1s;
    animation-name: changewidth;
    animation-iteration-count: infinite;
    animation-direction: normal;
    cursor: grab;
}

div#map .map-pointer svg{
	position: absolute;
	width: 300px;
	height: 300px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

html.desktop div#map .map-pointer svg{
    display: none;
}

div#map .map-pointer .compass{
    width: 200px;
    height: 200px;
    position: absolute;
    top: calc(0.5 * var(--map-pointer-size));
    left: calc(0.5 * var(--map-pointer-size));
    opacity: 0.5;
    transform: translate(-50%, -50%);
    background: radial-gradient(circle,
        rgba(var(--snowmap-blue)) 0%,
        rgba(255,255,0,0) 80%);
    clip-path: polygon(50% 48%, 100% 0%, -100% -100%);
}

div#map .precision{
    stroke: rgb(var(--snowmap-blue));
    fill: rgb(var(--snowmap-blue));
    stroke-dasharray: 1, 10;
    animation: 0.5s linear infinite reverse both running slidein;
}

div#map .map-pointer.hidden,
div#map .precision.hidden{
    display: none;
}

@keyframes slidein {
  from { stroke-dashoffset: 0; }
  to   { stroke-dashoffset: 10; }
}

div#map .azimuth-line{
    stroke-width: 1.5px;
    stroke: black;
    stroke-dasharray: 1, 5;
}

div.leaflet-zoom-anim .azimuth-line{
	display: none !important;
}

body[data-draw] div#map .azimuth-line{
    display: none;
}