*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
    outline: none;
	font-family: 'Lexend Deca', sans-serif;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    cursor: default;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: pan-x pan-y;
    font-size: inherit;
    background-repeat: no-repeat;
}

html{
    font-size: 13px;
    background: black;
    color: white;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
	filter: none;
}

body{
    width: 100%;
    height: 100%;
    overflow: hidden;
	min-height: -webkit-fill-available;
    padding-bottom: env(safe-area-inset-bottom);
}

img[src=""]{
	background: rgb(150,150,150);
	border: 1px solid rgb(200,200,200);
}

button{
	background: none;
}

ul{
	list-style: none;
}

a{
	color: rgb(var(--snowmap-blue));
	font-weight: bold;
	text-decoration: none;
}

a:hover{
	text-decoration: underline;
}

::-webkit-scrollbar {
    height: 8px;
    width: 11px;
}

html.mobile ::-webkit-scrollbar {
    display: none;
}

::-webkit-scrollbar-thumb {
    background: rgb(50 50 50);
    border-radius: 200px;
}

html.desktop ::-webkit-scrollbar-thumb:hover{
    background: rgb(70, 70, 70);
}

::-webkit-scrollbar-corner{
	display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*--------------------------------------------------------------------*/
/* Variables                                                          */
/*--------------------------------------------------------------------*/
html{
	--loading-screen-top-edge: 55%;
    --map-buttons-height: 40px;
    --nav-height: 65px;
    --tab-button-height: 35px;
    --hamburger-menu-width: 500px;
    --searchBar-margin: 10px;
    --filters-button-size: 32px;
    --margin-bottom-filters-item: 20px;
    
    --snowmap-blue: 0, 132, 219;
    --section-gray: rgb(30,30,30);
    --tab-button-color: rgb(50,50,50);
    --black1: rgba(0,0,0,0.75);

	--avalanche_risk-icon-size: 50px;
    --poi-icon-size: 36px;
    --poi-icon-gradient: radial-gradient(circle,
        white 0%,
        white 55%,
        black 58%,
        black 59%,
        white 62%,
        white 100%
    );
    --poi-icon-gradient-hover: radial-gradient(circle,
        white 0%,
        white 55%,
        black 58%,
        black 59%,
        rgb(var(
	--snowmap-blue)) 62%,
        rgb(var(--snowmap-blue)) 100%
    );
    --poi-icon-gradient-reversed: radial-gradient(circle,
        black 0%,
        black 55%,
        white 58%,
        white 59%,
        black 62%,
        black 100%
    );
    --poi-icon-gradient-reversed-hover: radial-gradient(circle,
        black 0%,
        black 55%,
        white 58%,
        white 59%,
        rgb(var(
	--snowmap-blue)) 62%,
        rgb(var(--snowmap-blue)) 100%
    );
    --search-result-gradient: radial-gradient(circle,
        white 0%,
        white 20%,
        black 25%,
        black 27%,
        rgb(var(--snowmap-blue)) 33%,
        rgb(var(--snowmap-blue)) 35%,
        rgba(0,0,0,0) 40%
    );
    --search-result-gradient-hover: radial-gradient(circle,
        rgb(var(--snowmap-blue)) 0%,
        rgb(var(--snowmap-blue)) 20%,
        white 25%,
        white 27%,
        black 33%,
        black 35%,
        rgba(0,0,0,0) 40%
    );
	--risk1: #cddf59;
	--risk2: #ffe27d;
	--risk3: #f0802b;
	--risk4: #db441a;
	--risk5: #e61212;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}