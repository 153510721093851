div.timeline{
    position: absolute;
    bottom: 120px;
    left: 70px;
    right: 70px;
    top: 150px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    pointer-events: none;
}

div.timeline.hidden{
    display: none;
}

html.desktop div.timeline{
    bottom: 220px;
    left: 20vw;
    right: 20vw;
}

@supports not ( -moz-appearance:none ){
    div.timeline input[type=range]{
        top: 0;
    }

    div.timeline input[type=range][data-minmax=max]{
        top: calc(20px + var(--filters-button-size));
    }
    
    div.timeline input[type=range]::-webkit-slider-runnable-track{
        -webkit-appearance: none;
        height: 0;
    }

    div.timeline input[type=range]::-webkit-slider-thumb{
        -webkit-appearance: none;
        width: var(--filters-button-size);
        height: var(--filters-button-size);
        border-radius: 100%;
        background: rgb(50,50,50);
        border: 2px solid rgb(100,100,100);
        box-sizing: border-box;
    }
    
    html.desktop div.timeline input[type=range]::-webkit-slider-thumb:hover{
        border: 2px solid rgb(150,150,150);
    }
}

@supports ( -moz-appearance:none ){
    div.timeline input[type=range]{
        top: calc(var(--filters-button-size) / 2);
        width: 100%;
    }

    div.timeline  input[type=range][data-minmax=max]{
        top: calc(var(--filters-button-size) + 4px);
    }
    
    div.timeline  input[type=range]::-moz-range-track{
        -moz-appearance: none;
        height: 0;
    }
    
    div.timeline  input[type=range]::-moz-range-thumb{
        -moz-appearance: none;
        width: var(--filters-button-size);
        height: var(--filters-button-size);
        border-radius: 100%;
        background: rgb(50,50,50);
        border: 2px solid rgb(100,100,100);
        padding: 0;
        box-sizing: border-box;
    }
    
    html.desktop div.timeline  input[type=range]::-moz-range-thumb:hover{
        border: 2px solid rgb(150,100,100);
    }
}

div.timeline input[type=range]{
    -webkit-appearance: none;
    background: none;
    display: block;
    height: 0;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;
    cursor: grab;
    pointer-events: auto;
}

div.timeline input[type=range]+label{
    position: absolute;
    font-size: 0.85rem;
    font-weight: 600;
    top: 0;
    pointer-events: none;
    z-index: 3;
    width: var(--filters-button-size);
    height: var(--filters-button-size);
    line-height: var(--filters-button-size);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    gap: 5px;
}

div.timeline input[type=range]+label span{
    white-space: nowrap;
}

div.timeline input[type=range]+label span.date{
    background: rgb(50,50,50);
    box-shadow: 0 0 0 2px rgb(100,100,100);
    border-radius: 100px;
    height: 17px;
    line-height: 17px;
    padding: 0 10px 0 10px;
}

div.timeline input[type=range]::-webkit-slider-runnable-track{
    -webkit-appearance: none;
    height: 0;
}

div.timeline button.previous,
div.timeline button.next,
div.timeline input[type=range]::-webkit-slider-thumb{
    -webkit-appearance: none;
    width: var(--filters-button-size);
    height: var(--filters-button-size);
    border-radius: 100%;
    background: rgb(50,50,50);
    border: 2px solid rgb(100,100,100);
    box-sizing: border-box;
    color: white;
}

div.timeline button.previous,
div.timeline button.next{
    position: absolute;
    bottom: 0;
    cursor: pointer;
    z-index: 9999;
    pointer-events: auto;
}

div.timeline button.previous{
    left: -50px;
    background-image: url('../images/previous.svg');
}

div.timeline button.next{
    right: -50px;
    background-image: url('../images/next.svg');
}
    
div.timeline button.previous:hover,
div.timeline button.next:hover,
div.timeline input[type=range]::-webkit-slider-thumb:hover{
    border: 2px solid rgb(150,150,150);
}

div.timeline div.slider::after{
    content: "";
    pointer-events: none;
    position: absolute;
    height: 8px;
    background: var(--black1);
    opacity: 0.75;
    top: 12px;
    left: 0;
    right: 0;
    border-radius: 8px;
    z-index: 1;
}

div.timeline button.play{
    background: rgba(0,0,0,0.75);
    color: white;
    background-image: url('../images/play.svg');
    background-repeat: no-repeat;
    background-position: center left 7px;
    padding: 7px 17px 7px 40px;
    border-radius: 100px;
    pointer-events: auto;
}

div.timeline button.play.playing{
    background-image: url('../images/stop.svg');
}

div.timeline div.slider{
    position: relative;
    width: 100%;
    height: var(--filters-button-size);
}

div.timeline div.slider p{
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.85rem;
    text-shadow:
        #ffffff 0 0 1px,
        #000000 0 0 3px,
        #000000 0 0 3px,
        #000000 0 0 3px;
}