.loading-screen {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 1;
    background-color: black;
}

.loading-screen .fader{
    background-color: black;
    transition-property: opacity;
    transition-duration: 1s;
    transition-delay: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
}

.loading-screen .fader.visible{
    opacity: 1;
}

body .loading-screen img.logo {
    width: 13rem;
}

body .loading-screen img.loading {
    width: 2rem;
    position: absolute;
    top: var(--loading-screen-top-edge);
    left: 50%;
    transform: translate(-50%, -50%);
}

body .loading-screen h1 {
    font-weight: 100;
    font-size: 4rem;
}

div.loading-screen.visible {
    opacity: 1;
    background-color: black;
}

div.loading-screen.hidden {
    opacity: 0;
    background-color: rgba(0,0,0,0.5);
    pointer-events: none;
}

div.sponsors {
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    padding: 20px 10px 20px 10px;
    background-color: transparent;
    transition-property: background-color;
    transition-duration: 2s;
    position: absolute;
    top: calc(var(--loading-screen-top-edge));
    bottom: 0;
    left: 0;
    right: 0;
    flex-direction: row;
    align-content: center;
}

html.desktop div.sponsors {
    padding: 0 100px 0 100px;
}

div.loading-screen.hidden div.sponsors {
    background-color: rgba(0,0,0,0.25);
}

div.sponsors img {
    height: 30px;
    display: flex;
    filter: saturate(0) brightness(125%);
}

div.loading-screen.visible {
    transition-property: opacity, background-color;
    transition-duration: 1s, 2s;
}

div.loading-screen.hidden {
    transition-property: opacity, background-color;
    transition-duration: 1s, 2s;
}

div.snowmap-logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    justify-content: center;
    top: 0;
    bottom: calc(100% - var(--loading-screen-top-edge));
    left: 0;
    right: 0;
}