div.tabs {
    width: 100%;
    height: 100%;
    position: relative;
}

div.tabs > div.panner{
	display: flex;
	flex-direction: row;
	gap: 0;
	transform: translateX(calc(-100% * var(--index)));
	height: calc(100% - var(--nav-height));
}

div.tabs > div.panner > div{
    min-width: 100%;
    height: 100%;
    background: black;
    overflow-y: scroll;
    overflow-x: hidden;
}

div.tabs > div.panner > div.hidden{
	opacity: 0;
}

div.tabs > div.panner > div.hidden *{
	display: none;
}

div.tabs > nav{
    background: black;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: var(--nav-height);
    box-shadow: 0 -1px 0 #333333;
}

div.tabs > nav > ul{
    width: 100%;
    height: 100%;
    list-style: none;
    display: flex;
    padding-top: 5px;
}

div.tabs > nav > ul > li{
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

div.tabs > nav > ul > li,
div.tabs > nav > ul > li *{
    cursor: pointer;
}

div.tabs > nav > ul > li > img{
    width: 36px;
    height: 36px;
    margin: -10px 0 -3px 0;
}

div.tabs > nav::after{
    background: rgba(255,255,255,0.25);
    position: absolute;
    height: 100%;
    top: 0;
    content: '';
    pointer-events: none;
    width: calc(100% / var(--numberOfItems));
    transform: translateX(calc(100% * var(--index)));
}

html.mobile div.tabs > nav::after{
	border-top: 5px solid rgb(var(--snowmap-blue));
}

div.tabs div.bookmarks > article > section.actions{
    padding: 10px;
}

div.panner > *{
    min-width: 100%;
	height: fit-content;
}

div.panner{
	transform: translateX(calc(-100% * var(--index)));
}

div.panner > *.hidden{
	overflow: hidden;
	height: 0;
	opacity: 0;
}


/*-----------------------------------------------------------------------------------------------*/
/* ANIMATIONS                                                                                    */
/*-----------------------------------------------------------------------------------------------*/
div.tabs{
	transition-property: transform;
	transition-duration: 0.5s;
	transition-timing-function: cubic-bezier(0.2, 0, 0.2, 1);
}

div.panner{
	transition-property: transform, opacity;
	transition-duration: 0.5s;
	transition-timing-function: cubic-bezier(0.2, 0, 0.2, 1);
}

div.tabs > div.panner,
div.tabs > nav::after{
	transition-property: transform, opacity;
	transition-duration: 0.5s;
	transition-timing-function: cubic-bezier(0.2, 0, 0.2, 1);
}


/*-----------------------------------------------------------------------------------------------*/
/* DESKTOP                                                                                       */
/*-----------------------------------------------------------------------------------------------*/

html.desktop div.tabs{
    max-width: var(--hamburger-menu-width);
    width: var(--hamburger-menu-width);
    overflow: hidden;
    z-index: 2;
    background: black;
    transform: translateX(-100%);
    transition-property: transform;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.2, 0, 0.2, 1);
}

html.desktop body[data-hamburger-fullscreen] div.tabs{
    max-width: none;
    width: auto;
}

html.desktop div.mainTab.map{
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
		
	transition-property: left;
	transition-duration: 0.5s;
	transition-timing-function: cubic-bezier(0.2, 0, 0.2, 1);
}

html.desktop body[data-hamburger="open"] div.tabs{
	transform: translateX(0);
}

html.desktop div.tabs > nav > ul > li:hover{
	box-shadow: inset 0px -5px 0px 0px rgb(var(--snowmap-blue));
}

html.desktop div.mainTab.map{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

html.desktop body[data-hamburger="open"] div.mainTab.map{
	left: var(--hamburger-menu-width);
}

html.desktop body div.mainTab.map #map{
	transition-property: left;
	transition-duration: 0.5s;
	transition-timing-function: cubic-bezier(0.2, 0, 0.2, 1);
}

html.desktop body[data-hamburger="open"] div.mainTab.map #map{
	left: calc(var(--hamburger-menu-width) * -0.5);
}