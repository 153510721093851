div.poiFilters{
    height: 100%;
    position: absolute;
    top:  0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    pointer-events: none;
    /* overflow: hidden; */
    isolation: isolate;
}

div.poiFilters > div.filters{
	opacity: 0;
    background: black;
    overflow-y: scroll;
    height: 100%;
}

div.poiFilters p{
    position: relative;
    padding-bottom: var(--margin-bottom-filters-item);
}


/* Range */

div.poiFilters article section p.filter-range{
    height: calc(var(--filters-button-size) + 2rem);
    padding: 0;
    overflow: hidden;
    margin-bottom: var(--margin-bottom-filters-item);
    position: relative;
}

div.poiFilters article section p.filter-range input[type=range]{
    -webkit-appearance: none;
    background: none;
    display: block;
    height: 0;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;
}

div.poiFilters article section p.filter-range input[type=range][data-minmax=max]{
    transform: rotate(180deg);
}

@supports not ( -moz-appearance:none ){
    div.poiFilters article section p.filter-range input[type=range]{
        top: 20px;
    }

    div.poiFilters article section p.filter-range input[type=range][data-minmax=max]{
        top: calc(20px + var(--filters-button-size));
    }
    
    div.poiFilters article section p.filter-range input[type=range]::-webkit-slider-runnable-track{
        -webkit-appearance: none;
        height: 0;
    }

    div.poiFilters article section p.filter-range input[type=range]::-webkit-slider-thumb{
        -webkit-appearance: none;
        width: var(--filters-button-size);
        height: var(--filters-button-size);
        border-radius: 100%;
        background: rgb(50,50,50);
        border: 2px solid rgb(100,100,100);
        box-sizing: border-box;
    }
}

@supports ( -moz-appearance:none ){
    div.poiFilters article section p.filter-range input[type=range]{
        top: calc(var(--filters-button-size) + 4px);
        width: 100%;
    }

    div.poiFilters article section p.filter-range  input[type=range][data-minmax=max]{
        top: calc(var(--filters-button-size) + 4px);
    }
    
    div.poiFilters article section p.filter-range  input[type=range]::-moz-range-track{
        -moz-appearance: none;
        height: 0;
    }
    
    div.poiFilters article section p.filter-range  input[type=range]::-moz-range-thumb{
        -moz-appearance: none;
        width: var(--filters-button-size);
        height: var(--filters-button-size);
        border-radius: 100%;
        background: rgb(50,50,50);
        border: 2px solid rgb(100,100,100);
        padding: 0;
        box-sizing: border-box;
    }
}

div.poiFilters article section p.filter-range  input[type=range]+label{
    position: absolute;
    font-size: 0.8rem;
    top: 20px;
    pointer-events: none;
    z-index: 3;
    width: var(--filters-button-size);
    height: var(--filters-button-size);
    line-height: var(--filters-button-size);
    display: flex;
    align-items: center;
    justify-content: center;
}

div.poiFilters article section p.filter-range  input[type=range]+label.infinity{
    background-image: url('../images/infini.svg');
}

div.poiFilters article section p.filter-range  input[type=range][data-minmax=min]+label{
    left: 0;
}

div.poiFilters article section p.filter-range  input[type=range][data-minmax=max]+label{
    right: 0;
}

div.poiFilters article section p.filter-range::after{
    content: "";
    pointer-events: none;
    position: absolute;
    height: 8px;
    background: rgb(50,50,50);
    top: var(--filters-button-size);
    left: 0;
    right: 0;
    border-radius: 8px;
    z-index: 1;
}


/* Checkbox */

div.poiFilters article section p.filter-boolean input[type=checkbox]{
    display: none;
}

div.poiFilters article section p.filter-boolean input[type=checkbox]+label,
div.poiFilters article section p.filter-date input[type=date]{
    width: 100%;
    height: var(--filters-button-size);
    border-radius: var(--filters-button-size);
    display: flex;
    align-items: center;
    padding: 0 5px 0 5px;
    background: rgb(50,50,50);
    border: 2px solid rgb(70,70,70);
    gap: 5px;
}

div.poiFilters article section p.filter-boolean input[type=checkbox]+label{
    color: gray;
}

div.poiFilters article section p.filter-boolean input[type=checkbox]+label img{
    opacity: 50%;
    width: 26px;
}

div.poiFilters article section p.filter-boolean input[type=checkbox]:checked+label{
    background: rgb(50,50,50);
    border: 2px solid rgb(100,100,100);
    color: white;
}

div.poiFilters article section p.filter-boolean input[type=checkbox]:checked+label img{
    opacity: 100%;
}

div.poiFilters article section p.filter-boolean input[type=checkbox]:checked+label {
    background-image: url('../images/validate.svg');
    background-repeat: no-repeat;
    background-position: center right 5px;
}

div.poiFilters article section p.filter-date input[type=date]{
    color: white;
    font-size: 1rem;
    background-image: url('../images/calendar.svg');
    background-repeat: no-repeat;
    background-position: center left 30px;
    padding-left: 60px;
    margin-top: 4px;
    margin-bottom: 10px;
    width: 100%;
}

div.poiFilters article section p.filter-date input[type="date"]::-webkit-calendar-picker-indicator {
    background: none;
    position: absolute;
    left: 80px;
    width: calc(100% - 145px);
    height: 28px;
    z-index: 9;
    opacity: 0;
}

div.poiFilters article section p.filter-date button{
    color: white;
    background: rgb(50,50,50);
    border: 2px solid rgb(100,100,100);
    width: 28px;
    height: 28px;
    line-height: 22px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    box-shadow: rgb(30,30,30) 0 0 0 4px;
    margin-top: -40px;
    z-index: 99999;
    position: absolute;
}

div.poiFilters article section p.filter-date button.left{
    left: 0;
}

div.poiFilters article section p.filter-date button.right{
    right: 0;
}

div.poiFilters article section p.filter-date{
    position: relative;
}


/*-----------------------------------------------------------------------------------------------*/
/* STATES                                                                                        */
/*-----------------------------------------------------------------------------------------------*/

div.poiFilters.open div.filters{
	opacity: 1;
	pointer-events: auto;
}

div#map[data-poi] div.poiFilters > button.filtersMenuButton{
	display: block;
	pointer-events: auto;
}

div#map div.poiFilters.open > button.filtersMenuButton{
	display: none;
}


/*-----------------------------------------------------------------------------------------------*/
/* DESKTOP                                                                                       */
/*-----------------------------------------------------------------------------------------------*/

html.desktop div.poiFilters{
    max-width: var(--hamburger-menu-width);
}

/*-----------------------------------------------------------------------------------------------*/
/* ANIMATIONS                                                                                    */
/*-----------------------------------------------------------------------------------------------*/

div.poiFilters > div.filters{
	transition-property: opacity;
	transition-duration: 0.25s;
	transition-timing-function: cubic-bezier(0.2, 0, 0.2, 1);
	transition-delay: 0.25s;
}

div.poiFilters.open > div.filters{
	transition-delay: 0.25s;
}