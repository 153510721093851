div.settings{
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
}

div.settings article img.chip{
	width: 80%;
    border-radius: 100%;
    border: 10px solid rgb(50,50,50);
    display: block;
    margin: 0 auto 0 auto;
}

div.settings article > section > p.links{
    padding-top: 10px;
}

div.settings article.sources > section > ul.sources{
	display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
	padding: 0
}

div.settings article.sources > section > ul.sources > li{
    background: rgb(50,50,50);
    border: 1px solid rgb(70,70,70);
    margin: 0;
    padding: 7px 10px 7px 10px;
    flex-grow: 1;
    height: 45px;
	display: flex;
    justify-content: center;
    align-items: center;
}

div.settings article.sources section > ul.sources > li a{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
}

div.settings article.sources section > ul.sources > li img{
    height: 30px;
    margin-right: 5px;
}

div.settings article.cookies > section > input[type="radio"]{
	display: none;
}
	
div.settings article.cookies > section > input[type="radio"]+label{
    border: 2px solid rgb(50,50,50);
    border-radius: 30px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    padding: 0 20px 0 20px;
    color: rgb(150,150,150);
    white-space: nowrap;
	margin-bottom: 5px;
	width: 49%;
}

div.settings article.cookies > section > input[type="radio"]:checked+label {
    background-repeat: no-repeat;
    background-position: right;
    color: white;
    border: 2px solid rgb(200,200,200);
    background-image: url("../images/validate.svg");
}

div.settings article.cookies > section > input[type="radio"]#cookies_no:checked+label {
    background-image: url("../images/close.svg");
}

div.settings section.cookies li ,
div.settings section.cookies p.refuse,
div.settings section.cookies p.accept {
    display: none;
}

div.settings section.cookies.refused li.accept {
    display: flex;
}

div.settings section.cookies.accepted li.refuse {
    display: flex;
}

div.settings section.cookies.refused p.accept {
    display: block;
}

div.settings section.cookies.accepted p.refuse {
    display: block;
}

article > section > button.submit[data-action="refuse"]{
    background-image: url(../images/close.svg);
}

div.bookmarks,
div.settings,
div.settings{
    padding-top: 50px;
}

div.bookmarks ul.selector,
div.settings ul.selector{
    padding: 20px 10px 0 10px;
}


/*-----------------------------------------------------------------------------------------------*/
/* DESKTOP                                                                                       */
/*-----------------------------------------------------------------------------------------------*/

html.desktop div.settings article.sources > section > ul.sources > li:hover{
	border-color: rgb(var(--snowmap-blue));
}

html.desktop article > section > button.submit:hover{
	border-color: rgb(var(--snowmap-blue));
}

html.desktop div.settings article.cookies > section > input[type="radio"]+label:hover{
	border-color: rgb(var(--snowmap-blue));
}