/*--------------------------------------------------------------------*/
/* Variables                                                          */
/*--------------------------------------------------------------------*/

div.articles article.filters{
    --filters-button-size: 32px;
    --margin-bottom-filters-item: 20px;
}


/*///////////////////////////////////////////////////////////////////////////////////////////*/
/* General                                                                                   */
/*///////////////////////////////////////////////////////////////////////////////////////////*/

div.articles article.filters section p{
    position: relative;
}


/*///////////////////////////////////////////////////////////////////////////////////////////*/
/* Range                                                                                     */
/*///////////////////////////////////////////////////////////////////////////////////////////*/

p.filter-range{
    height: calc(var(--filters-button-size) + 2rem);
    padding: 0;
    overflow: hidden;
    margin-bottom: 10px;
}

p.filter-range input[type=range]{
    -webkit-appearance: none;
    background: none;
    display: block;
    height: 0;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;
    cursor: grab;
}

p.filter-range input[type=range][data-minmax=max]{
    transform: rotate(180deg);
}


@supports not ( -moz-appearance:none ){
    p.filter-range input[type=range]{
        top: 20px;
    }

    p.filter-range input[type=range][data-minmax=max]{
        top: calc(20px + var(--filters-button-size));
    }
    
    p.filter-range input[type=range]::-webkit-slider-runnable-track{
        -webkit-appearance: none;
        height: 0;
    }

    p.filter-range input[type=range]::-webkit-slider-thumb{
        -webkit-appearance: none;
        width: var(--filters-button-size);
        height: var(--filters-button-size);
        border-radius: 100%;
        background: rgb(50,50,50);
        border: 2px solid rgb(100,100,100);
        box-sizing: border-box;
    }
    
    html.desktop p.filter-range input[type=range]::-webkit-slider-thumb:hover{
        border: 2px solid rgb(150,150,150);
    }
}

@supports ( -moz-appearance:none ){
    p.filter-range input[type=range]{
        top: calc(var(--filters-button-size) + 4px);
        width: 100%;
    }

    p.filter-range  input[type=range][data-minmax=max]{
        top: calc(var(--filters-button-size) + 4px);
    }
    
    p.filter-range  input[type=range]::-moz-range-track{
        -moz-appearance: none;
        height: 0;
    }
    
    p.filter-range  input[type=range]::-moz-range-thumb{
        -moz-appearance: none;
        width: var(--filters-button-size);
        height: var(--filters-button-size);
        border-radius: 100%;
        background: rgb(50,50,50);
        border: 2px solid rgb(100,100,100);
        padding: 0;
        box-sizing: border-box;
    }
    
    html.desktop p.filter-range  input[type=range]::-moz-range-thumb:hover{
        border: 2px solid rgb(150,100,100);
    }
}

p.filter-range  input[type=range]+label{
    position: absolute;
    font-size: 0.75rem;
    top: 20px;
    pointer-events: none;
    z-index: 3;
    width: var(--filters-button-size);
    height: var(--filters-button-size);
    line-height: var(--filters-button-size);
    display: flex;
    align-items: center;
    justify-content: center;
}

p.filter-range  input[type=range]+label.infinity{
    background-image: url('../images/infini.svg');
}

p.filter-range  input[type=range][data-minmax=min]+label{
    left: 0;
}

p.filter-range  input[type=range][data-minmax=max]+label{
    right: 0;
}

p.filter-range::after{
    content: "";
    pointer-events: none;
    position: absolute;
    height: 8px;
    background: rgb(50,50,50);
    top: var(--filters-button-size);
    left: 0;
    right: 0;
    border-radius: 8px;
    z-index: 1;
}


/*///////////////////////////////////////////////////////////////////////////////////////////*/
/* Checkbox                                                                                  */
/*///////////////////////////////////////////////////////////////////////////////////////////*/

p.filter-boolean{
    padding-bottom: 20px;
}

p.filter-boolean input[type=checkbox]{
    display: none;
}

p.filter-boolean input[type=checkbox]+label,
p.filter-date input[type=date]{
    width: 100%;
    height: var(--filters-button-size);
    border-radius: var(--filters-button-size);
    display: flex;
    align-items: center;
    padding: 0 5px 0 5px;
    background: rgb(50,50,50);
    border: 2px solid rgb(70,70,70);
    gap: 5px;
    cursor: pointer;
}

html.desktop p.filter-boolean input[type=checkbox]+label:hover{
    border: 2px solid rgb(100,100,100);
}

p.filter-boolean input[type=checkbox]+label{
    color: gray;
}

p.filter-boolean input[type=checkbox]+label img{
    opacity: 50%;
    width: 26px;
}

p.filter-boolean input[type=checkbox]:checked+label{
    background: rgb(50,50,50);
    border: 2px solid rgb(100,100,100);
    color: white;
}

p.filter-boolean input[type=checkbox]:checked+label img{
    opacity: 100%;
}

p.filter-boolean input[type=checkbox]:checked+label {
    background-image: url('../images/validate.svg');
    background-repeat: no-repeat;
    background-position: center right 5px;
}

p.filter-date input[type=date]{
    color: white;
    font-size: 0.9rem;
    background-image: url('../images/calendar.svg');
    background-repeat: no-repeat;
    background-position: center left 30px;
    padding-left: 60px;
    margin-top: 4px;
    margin-bottom: 10px;
    width: 100%;
    cursor: pointer;
}

p.filter-date input[type="date"]::-webkit-calendar-picker-indicator {
    background: none;
    position: absolute;
    left: 80px;
    width: calc(100% - 145px);
    height: 28px;
    z-index: 9;
    opacity: 0;
}

p.filter-date button{
    color: white;
    background: rgb(50,50,50);
    border: 2px solid rgb(100,100,100);
    width: 28px;
    height: 28px;
    line-height: 22px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    box-shadow: rgb(30,30,30) 0 0 0 4px;
    margin-top: -40px;
    z-index: 99999;
    position: absolute;
    cursor: pointer;
}

p.filter-date button.left{
    left: 0;
}

p.filter-date button.right{
    right: 0;
}


/* States */

div.articles[data-visibility="visible"]{
    opacity: 1;
    pointer-events: auto;
}

div.articles[data-poi="refuges"] article.refuges{
    display: block;
}
div.articles[data-poi="topos"] article.topos{
    display: block;
}
div.articles[data-poi="sorties"] article.sorties{
    display: block;
}