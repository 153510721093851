div.searchBar{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    z-index: 4;
}

div.searchBar > div.bar{
    position: relative;
    background: var(--black1);
    height: var(--map-buttons-height);
    position: absolute;
    top: var(--searchBar-margin);
    right: var(--searchBar-margin);
    left: var(--searchBar-margin);
    border-radius: 100px;
    z-index: 1;
    pointer-events: auto;
    backdrop-filter: blur(2px);
}

html.mobile div.searchBar > div.bar{
    right: var(--searchBar-margin);
}

div.searchBar > div.bar button.back{
    position: absolute;
    background-image: url('../images/back.svg');
    width: var(--map-buttons-height);
    height: var(--map-buttons-height);
    border-radius: 100%;
    z-index: 5;
    display: none;
}

div.searchBar > div.bar input{
    position: absolute;
    top: 0;
    right: var(--map-buttons-height);
    bottom: 0;
    left: 0;
    padding-left: 40px;
    color: white;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 4px 4px;
    background-size: calc(var(--map-buttons-height) - 5px);
    height: 100%;
}

div.searchBar > div.bar input.search{
    background-image: url('../images/recherche.svg');
}

div.searchBar > div.bar::after{
    display: none;
    content: '';
    position: absolute;
    right: calc(var(--map-buttons-height) * 2);
    top: 0;
    width: var(--map-buttons-height);
    height: var(--map-buttons-height);
    background-image: url('../images/loading-small.svg');
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none;
}

div.searchBar > div.bar input.filter{
    /* background-image: url('../images/filter.svg'); */
    display: none;
}

div.searchBar > div.bar img.logo{
    height: var(--map-buttons-height);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;
}

div.searchBar > div.bar button.erase{
    display: none;
    width: var(--map-buttons-height);
    height: var(--map-buttons-height);
    position: absolute;
    top: 0;
    right: var(--map-buttons-height);
    background-image: url('../images/close.svg');
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
    z-index: 5;
}


/* Search results */

div.searchBar ul.results{
    position: absolute;
    padding: 50px 0 0 0;
    top: 0;
    right: 0;
    left: 0;
    bottom:  0;
    background: black;
    opacity: 0;
    pointer-events: none;
    overflow-y: scroll;
}

div.searchBar > ul.results > li{
    border-bottom: 1px solid #424242;
    overflow: hidden;
    padding: 15px 0 15px 60px;
    background-repeat: no-repeat;
    background-position: center left 0;
    background-image: url('../images/placemark.svg');
    display: flex;
    flex-direction: column;
    opacity: 0;
}

div.searchBar > ul.results > li:not(.no-results),
div.searchBar > ul.results > li:not(.no-results) *{
    cursor: pointer;
}

div.searchBar > ul.results > li.blank{
    display: none;
}

div.searchBar > ul.results > li.no-results{
    background-image: none;
}

div.searchBar > ul.results > li.no-results > span.title{
    color: rgb(200, 200, 200);
    padding-top: 5px;
}

div.searchBar > ul.results > li.no-results div{
    display: none;
}
    
div.searchBar > ul.results > li > *{
    flex-shrink: 1;
    width: 0;
}

div.searchBar > ul.results > li.summit{
    background-image: url('../images/mountain-black.svg');
}

div.searchBar > ul.results > li > div{
    flex-direction: row;
    flex-wrap: nowrap;
	text-overflow: ellipsis;
	display: block !important;
	overflow: hidden;
	width: calc(100% - 40px);
	white-space: nowrap;
}

div.searchBar > ul.results > li > div > span{
    white-space: nowrap;
}

div.searchBar > ul.results > li > div > span:nth-child(n+2)::before{
    content: '\2022';
    margin: 0 5px 0 5px;
}

div.searchBar > ul.results > li > div > span{
    font-size:0.9rem;
    color: rgb(186, 186, 186);
}

div.searchBar > ul.results > li.sousprefecture,
div.searchBar > ul.results > li.prefecture,
div.searchBar > ul.results > li.commune{
    background-image: url('../images/placemark-important.svg');
}

div.searchBar > ul.results > li.pic,
div.searchBar > ul.results > li.rochers,
div.searchBar > ul.results > li.montagne,
div.searchBar > ul.results > li.col,
div.searchBar > ul.results > li.glacier,
div.searchBar > ul.results > li.cascade,
div.searchBar > ul.results > li.sommet,
div.searchBar > ul.results > li.crete,
div.searchBar > ul.results > li.versant{
    background-image: url('../images/mountain-small.svg');
}

div.searchBar > ul.results > li.refuge{
    background-image: url('../images/refuge.svg');
}


/*-----------------------------------------------------------------------------------------------*/
/* STATES                                                                                        */
/*-----------------------------------------------------------------------------------------------*/

div.searchBar[data-mode="searchResults"] ul.results{
    opacity: 1;
    pointer-events: auto;
}


div.searchBar[data-mode="searchResults"] ul.results > li{
    opacity: 1;
}

div.searchBar[data-mode="searchResults"] div.bar::after{
    display: none;
}

div.searchBar[data-mode="searchResults"] div.bar input{
    background-image: none;
}

div.searchBar[data-mode="searchResults"] > div.bar button.back{
    display: block;
}

div.searchBar input:not(:placeholder-shown) ~ button.erase{
    display: block;
}

div.searchBar[data-mode="loading"] > div.bar::after{
    display: block;
}

div.searchBar[data-mode="back"] input{
    display: none;
}

div.searchBar[data-mode="back"] > div.bar{
    background: none;
    backdrop-filter: none;
    box-shadow: none;
}

div.searchBar[data-mode="back"] > div.bar button.back{
    display: block;
    background-color: var(--black1);
}


div.searchBar[data-mode="back"] > div.bar img.logo{
    /* background-color: var(--black1); */
    border-radius: 100%;
}

div.searchBar[data-mode="back"] > div.bar::after{
    display: none;
}


div.searchBar[data-mode="filter"] input.search{
    display: none;
}


div.searchBar[data-mode="filter"] > div.bar input.filter{
    display: block;
}

/*-----------------------------------------------------------------------------------------------*/
/* DESKTOP                                                                                       */
/*-----------------------------------------------------------------------------------------------*/

html.desktop div.searchBar button.back{
    display: block;
    left: calc(-1 * (var(--map-buttons-height) + var(--searchBar-margin)));
    background-color: var(--black1);
    background-image: url('../images/hamburger.svg')
}

html.desktop ul.results{
    top: calc(var(--map-buttons-height) / 2 + var(--searchBar-margin));
    right: var(--searchBar-margin);
    left: var(--searchBar-margin);
    bottom: auto;
    background: black;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-top: calc(var(--map-buttons-height) / 2);
    box-shadow: black 0 0 0 1px;
    max-height: calc(100vh - var(--map-buttons-height) * 4);
    transform: scaleY(0);
    transform-origin: top;
    overflow: scroll;
}

html.desktop div.searchBar[data-mode="searchResults"] ul.results{
    transform: scaleY(1);
    /* padding-top:  5px; */
}

html.desktop div.searchBar[data-mode="searchResults"] div.bar{
    background-color: black;
}

html.desktop div.searchBar[data-mode="searchResults"] div.bar input.search{
    background-image: url('../images/recherche.svg');
}

html.desktop div.searchBar > ul.results > li:last-child{
    border-bottom: none;
}

html.desktop div.searchBar > ul.results > li:not(.no-results):hover{
    background-color: rgba(255,255,255,0.25);
	box-shadow: inset 5px 0px 0px 0px rgb(var(--snowmap-blue));
}









/* Back mode */

body[data-articles] div.searchBar > div.bar,
body[data-tabs="a-propos"] div.searchBar > div.bar,
body[data-tabs="mes-traces"] div.searchBar > div.bar{
    box-shadow: none;
    background-color: transparent;
    backdrop-filter: none;
    pointer-events: none;
}

body[data-articles] div.searchBar > div.bar *,
body[data-tabs="a-propos"] div.searchBar > div.bar *,
body[data-tabs="mes-traces"] div.searchBar > div.bar *{
    pointer-events: auto;
}

body[data-articles] div.searchBar > div.bar button.back,
body[data-tabs="a-propos"] div.searchBar > div.bar button.back,
body[data-tabs="mes-traces"] div.searchBar > div.bar button.back{
    display: block;
    background-color: var(--black1);
    background-image: url('../images/back.svg');
}

body[data-articles] div.searchBar > div.bar input,
body[data-tabs="a-propos"] div.searchBar > div.bar input,
body[data-tabs="mes-traces"] div.searchBar > div.bar input{
    display: none;
}

body[data-articles] html.desktop div.searchBar div.bar::after,
body[data-tabs="a-propos"] html.desktop div.searchBar div.bar::after,
body[data-tabs="mes-traces"] html.desktop div.searchBar div.bar::after{
    content: '';
    display: block;
    position: absolute;
    width: calc(var(--map-buttons-height) + var(--searchBar-margin));
    height: var(--map-buttons-height);
    top: 0;
    right: 0;
    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
    background: var(--black1);
    box-shadow: var(--black1) 0 0 0 1px;
}




/* Filter mode */

body[data-tabs="reports"] div.searchBar button.back{
    background-image: url('../images/back.svg');
}

body[data-tabs="reports"] div.searchBar > div.bar input.search{
    display: none;
}

body[data-tabs="reports"] div.searchBar > div.bar input.filter{
    display: block;
}

html.desktop[data-tabs="reports"] div.searchBar > div.bar input.filter{
    border-radius: 100px;
    box-shadow: #3d3d3d 0 0 0 1px;
    width: 410px;
    background-color: var(--black1);
    /*backdrop-filter: blur(2px);*/
}

html.desktop[data-tabs="reports"] div.searchBar div.bar{
    background: none;
    backdrop-filter: none;
    box-shadow: none;
}
div.searchBar{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    pointer-events: none;
    z-index: 4;
    height: calc(var(--searchBar-margin) * 2 + var(--map-buttons-height));
    overflow: hidden;
}

div.searchBar > div.bar{
    position: relative;
    background: var(--black1);
    height: var(--map-buttons-height);
    position: absolute;
    top: var(--searchBar-margin);
    right: calc(var(--map-buttons-height) + var(--searchBar-margin) * 3);
    left: var(
    --searchBar-margin);
    border-radius: 100px;
    box-shadow: #3d3d3d 0 0 0 1px;
    z-index: 1;
    pointer-events: auto;
    /*backdrop-filter: blur(2px);*/
}

div.searchBar > div.bar button.back{
    position: absolute;
    background-image: url('../images/back.svg');
    width: var(--map-buttons-height);
    height: var(--map-buttons-height);
    border-radius: 100%;
    z-index: 5;
    display: none;
}

div.searchBar > div.bar input{
    position: absolute;
    top: 0;
    right: var(--map-buttons-height);
    bottom: 0;
    left: 0;
    padding-left: 40px;
    color: white;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 4px 4px;
    background-size: calc(var(--map-buttons-height) - 5px);
    height: 100%;
}

div.searchBar > div.bar input.search{
    background-image: url('../images/recherche.svg');
}

div.searchBar > div.bar::after{
    display: none;
    content: '';
    position: absolute;
    right: calc(var(--map-buttons-height) * 2);
    top: 0;
    width: var(--map-buttons-height);
    height: var(--map-buttons-height);
    background-image: url('../images/loading-small.svg');
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none;
}

html.desktop div.searchBar > div.bar input.filter{
    background-image: url('../images/filter.svg');
}

div.searchBar > div.bar img.logo{
    height: var(--map-buttons-height);
    position: absolute;
    top: 0;
    right: calc(-1 * (var(--map-buttons-height) + var(--searchBar-margin) * 2));
    riht: calc(-1 * (var(--map-buttons-height) + var(--searchBar-margin) * 2));
    z-index: 4;
    /* box-shadow: #3d3d3d 0 0 0 1px; */
    border-radius: 100%;
}

html.mobile div.searchBar > div.bar img.logo{
    right: 0;
}

div.searchBar > div.bar button.erase{
    display: none;
    width: var(--map-buttons-height);
    height: var(--map-buttons-height);
    position: absolute;
    top: 0;
    right: var(--map-buttons-height);
    background-image: url('../images/close.svg');
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
    z-index: 5;
}


/* Search results */

div.searchBar ul.results{
    position: absolute;
    padding: 50px 0 0 0;
    top: 0;
    right: 0;
    left: 0;
    bottom:  0;
    background: black;
    opacity: 0;
    pointer-events: none;
    overflow-y: scroll;
}

div.searchBar > ul.results > li{
    border-bottom: 1px solid #424242;
    overflow: hidden;
    padding: 15px 0 15px 60px;
    background-repeat: no-repeat;
    background-position: center left 0;
    background-image: url('../images/placemark.svg');
    display: flex;
    flex-direction: column;
    opacity: 0;
    position: relative;
}

div.searchBar > ul.results > li:not(.no-results),
div.searchBar > ul.results > li:not(.no-results) *{
    cursor: pointer;
}

div.searchBar > ul.results > li > div > span:empty,
div.searchBar > ul.results > li.blank{
    display: none;
}

div.searchBar > ul.results > li.no-results{
    background-image: none;
}

div.searchBar > ul.results > li.no-results > span.title{
    color: rgb(200, 200, 200);
    padding-top: 5px;
}

div.searchBar > ul.results > li.no-results div{
    display: none;
}
    
div.searchBar > ul.results > li > *{
    flex-shrink: 1;
    width: 0;
}

div.searchBar > ul.results > li.summit{
    background-image: url('../images/mountain-black.svg');
}

div.searchBar > ul.results > li > span.title{
    font-size: 1rem;
    flex-shrink: 0;
    color: white;
    text-overflow: ellipsis;
    display: block !important;
    overflow: hidden;
    width: calc(100% - 40px);
    white-space: nowrap;
}

div.searchBar > ul.results > li > div{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

div.searchBar > ul.results > li > div > span{
    white-space: nowrap;
}

div.searchBar > ul.results > li > div > span:nth-child(n+2)::before{
    content: '\2022';
    margin: 0 5px 0 5px;
}

div.searchBar > ul.results > li > div > span{
    font-size:0.9rem;
    color: rgb(186, 186, 186);
}

div.searchBar > ul.results > li.sousprefecture,
div.searchBar > ul.results > li.prefecture,
div.searchBar > ul.results > li.commune{
    background-image: url('../images/placemark-important.svg');
}

div.searchBar > ul.results > li.pic,
div.searchBar > ul.results > li.rochers,
div.searchBar > ul.results > li.montagne,
div.searchBar > ul.results > li.col,
div.searchBar > ul.results > li.glacier,
div.searchBar > ul.results > li.cascade,
div.searchBar > ul.results > li.sommet,
div.searchBar > ul.results > li.crete,
div.searchBar > ul.results > li.versant{
    background-image: url('../images/mountain-small.svg');
}

div.searchBar > ul.results > li.refuge{
    background-image: url('../images/refuge.svg');
}

div.searchBar > ul.results div.country{
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    border-radius: 100%;
    top: 50%;
    transform: translate(0, -50%);
}

div.searchBar > ul.results li[data-country="france"] div.country{
    background-image: url('../images/flag-france-round.svg');
}

div.searchBar > ul.results li[data-country="switzerland"] div.country{
    background-image: url('../images/flag-switzerland-round.svg');
}

div.searchBar > ul.results li[data-country="italy"] div.country{
    background-image: url('../images/flag-italy-round.svg');
}

div.searchBar > ul.results li[data-country="spain"] div.country{
    background-image: url('../images/flag-spain-round.svg');
}


/*-----------------------------------------------------------------------------------------------*/
/* STATES                                                                                        */
/*-----------------------------------------------------------------------------------------------*/

div.searchBar[data-mode="searchResults"]{
    height: auto;
}

div.searchBar[data-mode="searchResults"] ul.results{
    opacity: 1;
    pointer-events: auto;
}

div.searchBar[data-mode="searchResults"] ul.results > li{
    opacity: 1;
}

div.searchBar[data-mode="searchResults"] div.bar::after{
    display: none;
}

div.searchBar[data-mode="searchResults"] div.bar input{
    background-image: none;
}

div.searchBar[data-mode="searchResults"] > div.bar button.back,
div.searchBar[data-mode="filter"] > div.bar button.back{
    display: block;
}

div.searchBar input:not(:placeholder-shown) ~ button.erase{
    display: block;
}

div.searchBar[data-mode="loading"] > div.bar::after{
    display: block;
}

div.searchBar[data-mode="back"] input{
    display: none;
}

div.searchBar[data-mode="back"] > div.bar{
    background: none;
    backdrop-filter: none;
    box-shadow: none;
}

div.searchBar[data-mode="back"] > div.bar button.back{
    display: block;
    background-color: var(--black1);
}


body[data-hamburger-fullscreen] div.searchBar[data-mode="back"] > div.bar img.logo{
    display: none;
}

div.searchBar[data-mode="back"] > div.bar::after{
    display: none;
}


/*-----------------------------------------------------------------------------------------------*/
/* DESKTOP                                                                                       */
/*-----------------------------------------------------------------------------------------------*/

html.desktop div.searchBar button.back{
    display: block;
    left: calc(-1 * (var(--map-buttons-height) + var(--searchBar-margin)));
    background-color: var(--black1);
    box-shadow: #3d3d3d 0 0 0 1px;
}

html.desktop div.searchBar button.back:hover{
    box-shadow: rgb(var(--snowmap-blue)) 0 0 0 1px;
}

html.desktop body:not([data-hamburger]) div.searchBar button.back{
    background-image: url('../images/hamburger.svg');
}

html.desktop div.searchBar{
    left: calc(var(--map-buttons-height) + var(--searchBar-margin));
    overflow: visible;
    max-width: calc(var(--hamburger-menu-width) + 50px - var(--map-buttons-height));
}

html.desktop ul.results{
    top: calc(var(--map-buttons-height) / 2 + var(--searchBar-margin));
    right: 70px;
    left: var(--searchBar-margin);
    bottom: auto;
    background: black;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-top: calc(var(--map-buttons-height) / 2);
    box-shadow: black 0 0 0 1px;
    max-height: calc(100vh - var(--map-buttons-height) * 4);
    transform: scaleY(0);
    transform-origin: top;
    overflow: scroll;
}

body div.searchBar[data-mode="searchResults"]{
    bottom: 0;
}

html.desktop div.searchBar[data-mode="searchResults"] ul.results{
    transform: scaleY(1);
    /* padding-top:  5px; */
}

html.desktop div.searchBar[data-mode="searchResults"] div.bar{
    background-color: black;
}

html.desktop div.searchBar[data-mode="searchResults"] div.bar input.search{
    background-image: url('../images/recherche.svg');
}

html.desktop div.searchBar > ul.results > li:last-child{
    border-bottom: none;
}

html.desktop div.searchBar > ul.results > li:not(.no-results):hover{
    background-color: rgba(255,255,255,0.25);
	box-shadow: inset 5px 0px 0px 0px rgb(var(--snowmap-blue));
}









/* Back mode */

body[data-articles] div.searchBar > div.bar,
body[data-tabs="a-propos"] div.searchBar > div.bar,
body[data-tabs="mes-traces"] div.searchBar > div.bar{
    box-shadow: none;
    background-color: transparent;
    backdrop-filter: none;
    pointer-events: none;
}

body[data-articles] div.searchBar > div.bar *,
body[data-tabs="a-propos"] div.searchBar > div.bar *,
body[data-tabs="mes-traces"] div.searchBar > div.bar *{
    pointer-events: auto;
}

body[data-articles] div.searchBar > div.bar button.back,
body[data-tabs="a-propos"] div.searchBar > div.bar button.back,
body[data-tabs="mes-traces"] div.searchBar > div.bar button.back{
    display: block;
    background-color: var(--black1);
    background-image: url('../images/back.svg');
}

body[data-articles] div.searchBar > div.bar input,
body[data-tabs="a-propos"] div.searchBar > div.bar input,
body[data-tabs="mes-traces"] div.searchBar > div.bar input{
    display: none;
}

body[data-articles] html.desktop div.searchBar div.bar::after,
body[data-tabs="a-propos"] html.desktop div.searchBar div.bar::after,
body[data-tabs="mes-traces"] html.desktop div.searchBar div.bar::after{
    content: '';
    display: block;
    position: absolute;
    width: calc(var(--map-buttons-height) + var(--searchBar-margin));
    height: var(--map-buttons-height);
    top: 0;
    right: 0;
    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
    background: var(--black1);
    box-shadow: var(--black1) 0 0 0 1px;
}




/* Filter mode */

body[data-tabs="reports"] div.searchBar button.back{
    background-image: url('../images/back.svg');
}

body[data-tabs="reports"] div.searchBar > div.bar input.search{
    display: none;
}

body[data-tabs="reports"] div.searchBar > div.bar input.filter{
    display: block;
}

html.desktop[data-tabs="reports"] div.searchBar > div.bar input.filter{
    border-radius: 100px;
    box-shadow: #3d3d3d 0 0 0 1px;
    width: 410px;
    background-color: var(--black1);
    /*backdrop-filter: blur(2px);*/
}

html.desktop[data-tabs="reports"] div.searchBar div.bar{
    background: none;
    backdrop-filter: none;
    box-shadow: none;
}


html.desktop[data-tabs="reports"] div.searchBar button.erase{
    right: calc(var(--map-buttons-height) * 2);
}



/*-----------------------------------------------------------------------------------------------*/
/* ANIMATIONS                                                                                    */
/*-----------------------------------------------------------------------------------------------*/


html.desktop div.searchBar button.erase{
    right: 10px;
}

html.desktop div.searchBar > div.bar::after{
    right: calc(10px + var(--map-buttons-height));
}