/*-----------------------------------------------------------------------------------------------*/
/* STYLING                                                                                       */
/*-----------------------------------------------------------------------------------------------*/

div.mapButtons ul{
    list-style: none;
}

div.mapButtons button{
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 5px;
    pointer-events: auto;
    background: none;
    border: none;
    position: relative;
}

div.mapButtons ul.subMenu.left button{
    flex-direction: row;
}

div.mapButtons ul.subMenu.right button{
    flex-direction: row-reverse;
}

div.mapButtons button > img{
    background: var(--black1);
    width: var(--map-buttons-height);
    aspect-ratio: 1;
    border-radius: 100%;
    overflow: hidden;
}

div.mapButtons button > img{
    background: var(--black1);
    width: var(--map-buttons-height);
    aspect-ratio: 1;
    border-radius: 100%;
    overflow: hidden;
}

div.mapButtons button > span{
    background: var(--black1);
    border-radius: 50px;
    white-space: nowrap;
    color: white;
    padding: 4px 11px 4px 11px;
    font-size: 1rem;
}

div.mapButtons > div > ul > li > button > span,
div.mapButtons > div > button > span{
    display: none;
}



/*-----------------------------------------------------------------------------------------------*/
/* LAYOUT                                                                                        */
/*-----------------------------------------------------------------------------------------------*/

/* Grid layout */

div.mapButtons{
    position: absolute;
    top: 100px;
    right: 0;
    bottom: 20px;
    left: 0;
    pointer-events: none;
    z-index: 1;
}

div.mapButtons > div{
    display: grid;
    height: 100%;
    justify-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

div.mapButtons div.mapControls{
    pointer-events: none;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 1fr);
}

div.mapButtons div.drawControls{
    grid-auto-flow: column;
    align-items: end;
    max-width: 500px;
    margin: 0 auto 0 auto;
}

/* Grid items */

div.mapButtons button[data-group="draw"][data-action="start"]{
    grid-row: 1;
    grid-column: 1;
    align-self: start;
}

html.desktop div.mapButtons button[data-group="location"]{
    display: none;
}

div.mapButtons button[data-group="location"]{
    grid-row: 1;
    grid-column: 1;
    align-self: start;
    padding: 10px;
}

div.mapButtons button[data-group="location"][data-action="toggle"]{
    margin-top: 50px;
}

div.mapButtons button[data-group="location"][data-action="center"]{
    display: none;
    margin-top: 100px;
}

div.mapButtons button[data-group="location"][data-action="toggle"].selected+button{
    display: block;
}

div.mapButtons ul.subMenu[data-group="backgroundLayer"]{
    grid-row: 1;
    grid-column: 6;
    align-self: start;
}

div.mapButtons ul.subMenu[data-group="poi"]{
    grid-row: 2;
    grid-column: 1;
    align-self: end;
}

div.mapButtons button[data-action="snow_cover"]{
    grid-row: 2;
    grid-column: 2;
    align-self: end;
}

div.mapButtons button[data-action="avalanche_risk"]{
    grid-row: 2;
    grid-column: 3;
    align-self: end;

}

div.mapButtons button[data-action="fresh_snow"]{
    grid-row: 2;
    grid-column: 4;
    align-self: end;
}

div.mapButtons button[data-action="slopes"]{
    grid-row: 2;
    grid-column: 5;
    align-self: end;
}

div.mapButtons button[data-action="itineraries"]{
    grid-row: 2;
    grid-column: 6;
    align-self: end;
}

/* Submenus */

div.mapButtons ul.subMenu{
    width: var(--map-buttons-height);
    height: var(--map-buttons-height);
    display: flex;
    gap: 20px;
    pointer-events: none;
}

div.mapButtons ul.subMenu ul button{
    pointer-events: none;
}

div.mapButtons ul.subMenu[data-group="backgroundLayer"]{
    flex-direction: column;
}

div.mapButtons ul.subMenu[data-group="poi"]{
    flex-direction: column-reverse;
}

div.mapButtons ul.subMenu > li > ul{
    display: flex;
    gap: 5px;
}

div.mapButtons ul.subMenu[data-group="backgroundLayer"] > li > ul{
    flex-direction: column;
}

div.mapButtons ul.subMenu[data-group="poi"] > li > ul{
    flex-direction: column-reverse;
}

div.mapButtons ul.subMenu ul > li{
    display: flex;
    flex-basis: 10px;
}

div.mapButtons ul.subMenu[data-group="backgroundLayer"] ul > li > button,
div.mapButtons ul.subMenu[data-group="backgroundLayer"] ul > li{
    flex-direction: row-reverse;
}

div.mapButtons ul.zoom-buttons{
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
}


html.mobile div.mapButtons ul.zoom-buttons{
    display: none;
}

div.map div.map-attributions {
    width: 20px;
    height: 20px;
    background-color: var(--black1);
    background-image: url(../images/information.svg);
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 7px;
    box-shadow: 0 0 0 1px rgb(255 255 255 / 50%);
}

html.mobile div.map div.map-attributions {
    top: 170px;
    right: 25px;
}


/*-----------------------------------------------------------------------------------------------*/
/* STATES                                                                                        */
/*-----------------------------------------------------------------------------------------------*/

div.mapButtons div.mapControls button:not([data-type="button"]){
    opacity: 0.75;
}

div.mapButtons div.mapControls ul.subMenu.selected > li > button{
    pointer-events: auto;
}

div.mapButtons div.mapControls ul.subMenu.selected > li > button,
div.mapButtons div.mapControls button.selected{
    opacity: 1;
}

div.mapButtons ul.subMenu.selected ul button{
    pointer-events: auto;
}

div.mapButtons ul.selected > li > button > img,
div.mapButtons button.selected > span,
div.mapButtons button.selected > img{
    box-shadow: white 0 0 0 1px;
}

div.mapButtons ul.subMenu ul button > img{
    transform: scale(0);
}

div.mapButtons ul.subMenu ul button > span{
    opacity: 0;
    pointer-events: none;
}

div.mapButtons ul.subMenu.selected ul button > img{
    transform: scale(1);
}

div.mapButtons ul.subMenu.selected ul button > span{
    opacity: 1;
}

/* Draw mode */

div.mapButtons div.mapControls,
body[data-draw] div.mapButtons div.drawControls{
    display: grid;
}

div.mapButtons div.drawControls,
body[data-draw] div.mapButtons div.mapControls{
    display: none;
}

body[data-draw="edited"] div.mapButtons button[data-action="edit"],
body[data-draw="started"] div.mapButtons button[data-action="edit"]{
    display: none;
}
body[data-draw="edited"] div.mapButtons button[data-action="information"],
body[data-draw="started"] div.mapButtons button[data-action="information"],
body[data-draw="edited"] div.mapButtons button[data-action="hide"],
body[data-draw="started"] div.mapButtons button[data-action="hide"]{
    display: none;
}
body[data-draw="edited"] div.mapButtons button[data-action="download"],
body[data-draw="started"] div.mapButtons button[data-action="download"]{
    display: none;
}
body[data-draw="edited"] div.mapButtons button[data-action="delete"],
body[data-draw="started"] div.mapButtons button[data-action="delete"]{
    display: none;
}

body[data-draw="selected"] div.mapButtons button[data-action="stop"]{
    display: none;
}
body[data-draw="selected"] div.mapButtons button[data-action="undo"]{
    display: none;
}


/*-----------------------------------------------------------------------------------------------*/
/* ANIMATIONS                                                                                    */
/*-----------------------------------------------------------------------------------------------*/

div.mapButtons ul.subMenu ul button > img{
    transition-property: transform;
    transition-duration: 0.5s;
}

div.mapButtons ul.subMenu ul button > span{
    transition-property: opacity;
    transition-duration: 0.5s;
}

div.mapButtons ul.subMenu button > span,
div.mapButtons ul.subMenu button > img{
    transition-delay: calc(var(--reverse-order) * 50ms);
}

div.mapButtons ul.subMenu.selected button > span,
div.mapButtons ul.subMenu.selected button > img{
    transition-delay: calc(var(--order) * 50ms);
}

html.desktop div.mapButtons > div > ul > li > button > img+span,
html.desktop div.mapButtons > div > button > img+span,
html.desktop div.mapButtons > div > button > img+span{
    transition-property: opacity, transform;
    transition-duration: 0.5s, 0.5s;
}



/*-----------------------------------------------------------------------------------------------*/
/* DESKTOP                                                                                       */
/*-----------------------------------------------------------------------------------------------*/

html.desktop div.mapButtons{
    top: 90px;
    left: 40px;
    right: 40px;
    bottom: 60px;
}

html.desktop div.mapButtons div.mapControls{
    max-width: 500px;
    position: static;
    margin-right: 130px;
}

html.desktop div.mapButtons ul.subMenu[data-group="backgroundLayer"]{
    position: absolute;
    right: 30px;
    top: 0;
}

html.desktop div.mapButtons > div > ul > li > button > span,
html.desktop div.mapButtons > div > button > span{
    display: block;
    opacity: 0;
    position: absolute;
    top: -40px;
    left: 0;
    transform: 
        translateX(calc(-50% + (var(--map-buttons-height) / 2)))
        translateY(16px)
    ;
}

html.desktop div.mapButtons > div > ul:not(.selected) > li > button > img:hover+span,
html.desktop div.mapButtons > div > button > img:hover+span{
    opacity: 1;
    transform: 
        translateX(calc(-50% + (var(--map-buttons-height) / 2)))
        translateY(0)
    ;
}

html.desktop div.mapButtons > div > ul > li > button[data-group="backgroundLayer"] > span,
html.desktop div.mapButtons > div > button[data-group="backgroundLayer"] > span,
html.desktop div.mapButtons > div > ul > li > button[data-group="draw"] > span,
html.desktop div.mapButtons > div > button[data-group="draw"] > span{
    top: 40px;
    transform: 
        translateX(calc(-50% + (var(--map-buttons-height) / 2)))
        translateY(0)
    ;
}

html.desktop div.mapButtons > div > ul:not(.selected) > li > button[data-group="backgroundLayer"] > img:hover+span,
html.desktop div.mapButtons > div > ul:not(.selected) > li > button[data-group="draw"] > img:hover+span,
html.desktop div.mapButtons > div > button[data-group="draw"] > img:hover+span{
    transform: 
        translateX(calc(-50% + (var(--map-buttons-height) / 2)))
        translateY(16px);
}


div.mapButtons button.filtersMenuButton{
    position: absolute;
    background: rgba(0,0,0,0.75);
    color: white;
    background-image: url('../images/filters.svg');
    background-repeat: no-repeat;
    background-position: center left 7px;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    padding: 7px 17px 7px 40px;
    border-radius: 100px;
    display: none;
}

body[data-poilayer] div.mapButtons button.filtersMenuButton{
    display: block;
    top: 0;
}